import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from "@angular/router";
import { Location, PlatformLocation } from "@angular/common";
import { TokenService } from "./../token/token.service";
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LocalstorageService } from '../localstorage/localstorage.service';
declare let moment: any;

@Injectable()
class Interceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private location: Location,
    private tokenService: TokenService,
    private spinner: NgxSpinnerService,
    private platfLocation: PlatformLocation,
    private toastr: ToastrService,
    private localStorageService: LocalstorageService
  ) {
    this.platfLocation.onPopState(() => {
      this.resetLoading();
    });

    this.location.onUrlChange(() => {
      this.totalRequests = 0;
    });
  }

  totalRequests = 0;
  whiteList = [
    (environment.apiGestorUrlLocal + 'api/notificacao-sistema/avisos'),
    (environment.apiGestorUrlLocal + 'api/chat/contadores-meus-chats'),
    (environment.apiGestorUrlLocal + 'api/chat/listagem-meus-chats'),
    (environment.apiGestorUrlLocal + 'api/whatsapp/obter-integracao'),
    (environment.apiGestorUrlLocal + 'api/clientes/dropdown-paginado'),
    (environment.apiGestorUrlLocal + 'api/clienteContato/list'),
    (environment.apiGestorUrlLocal + 'api/whatsapp/obter-integracao'),
    (environment.apiGestorUrlLocal + 'api/whatsapp/testar-integracoes'),
    (environment.apiGestorUrlLocal + 'api/base-conhecimento/arvore'),
    (environment.apiGestorUrlLocal + 'api/campos-personalizados/validacao/especificas'),
    (environment.apiGestorUrlLocal + 'api/integracao-agenda/validaAuthTecnico'),
    (environment.apiGestorUrlLocal + 'api/chatGpt/comment-sugestao-de-texto-modal/'),
    (environment.apiGestorUrlLocal + 'api/integracao-agenda/validaAuthOutlookTecnico'),
    (environment.apiGestorUrlLocal + 'api/chamados/contador/')
  ];
  intervalLoading;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.verificaWhitelist(request.url);
    this.totalRequests++;

    this.verificaLogoutAutomatico();

    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
      this.verificaStatusEvent(event['status']);

      if (this.totalRequests <= 0) {
        this.spinner.hide();
      }

      return event;
    }), catchError((error: HttpErrorResponse) => {
      this.resetLoading();
      this.msgErrorResponse(error)
      return throwError(error)
    }));
  }

  verificaStatusEvent(status) {
    if (status) {
      this.totalRequests--;
    }
  }

  msgErrorResponse(error): void {
    if (
      error.error &&
      (error.error.error_code || error.status) === 401 &&
      error.error.error !== "Falha no login." &&
      error.error.error_msg !== "Credenciais inválidas!"
    ) {
      this.toastr.show(error.error ? error.error : error, "", {
        disableTimeOut: true,
        closeButton: false,
        enableHtml: true,
        tapToDismiss: true,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-danger alert-notify",
      });
      setTimeout(() => {
        this.salvaTemaPortal(error.error ? error.error : error);
        this.resetCachedFiles();
      }, 1000);
    } else if (error && error.status === 406) {
      this.toastr.show(error.error ? error.error : error, "", {
        disableTimeOut: true,
        closeButton: false,
        enableHtml: true,
        tapToDismiss: true,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-danger alert-notify",
      });
      localStorage.removeItem("deviceId");
      setTimeout(() => {
        this.salvaTemaPortal(error.error ? error.error : error);
        this.resetCachedFiles();
        this.resetLoading();
      }, 1000);
    } else if (error.error.error_msg === "Credenciais inválidas!") {
      this.toastr.show(error.error.error_msg ? error.error.error_msg : error, "", {
        disableTimeOut: true,
        closeButton: false,
        enableHtml: true,
        tapToDismiss: true,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-danger alert-notify",
      });
    } else if (error.error.error !== "Falha no login.") {
      this.toastr.show(error.error ? error.error : error, "", {
        disableTimeOut: true,
        closeButton: false,
        enableHtml: true,
        tapToDismiss: true,
        titleClass: "alert-title",
        positionClass: "toast-top-center",
        toastClass:
          "ngx-toastr alert alert-dismissible alert-danger alert-notify",
      });
    }
  }

  resetLoading(): void {
    this.totalRequests = 0;
    this.spinner.hide();
    clearInterval(this.intervalLoading);
  }

  resetCachedFiles(): void {
    window.location.replace(window.location.href);
  }

  verificaWhitelist(url: string): void {
    if(!this.router.routerState.snapshot.url.includes('/tickets/details/') && !this.router.routerState.snapshot.url.includes('/tickets/list')) {
      var urlWithoutParams = url.split('?');
      if (urlWithoutParams[0] &&
        (urlWithoutParams[0].includes('dropdown') ||
          urlWithoutParams[0].includes('api/campos-personalizados/lista/opcoes/todas/') ||
          urlWithoutParams[0].includes('api/categorias-multi-niveis/arvore-cliente'))) {
        return;
      }
  
      if (this.whiteList.includes(urlWithoutParams[0]) === false) {
        this.spinner.show();
        this.verificaLoadingTravado();
      }
    }
  }

  calculaTempoLogado(): number {
    let user = this.tokenService.getUsuario();
    let agora: any = new Date();
    let ultimo_login: any = new Date(user.ultimo_login);
    let diff = new moment.duration(agora - ultimo_login);

    return Math.floor(diff.asHours());
  }

  verificaLogoutAutomatico(): void {
    if (this.tokenService.hasToken()) {
      this.verificaDiffTimeEFullscreen();
    } else {
      this.verificaActualRoute();
    }
  }

  redirecionaTelaLogin() {
    this.resetLoading();
    this.router.navigate(['/login']);
  }

  salvaTemaPortal(sessao_expirada?) {
    this.localStorageService.logoutWithLocalData(null, sessao_expirada ? sessao_expirada : null);
  }

  verificaDiffTimeEFullscreen() {
    const diffTime = this.calculaTempoLogado();
    if (diffTime >= 10 && document.fullscreenElement === null) {
      let actualRoute = this.location.path().substring(1).split('/');
      if(actualRoute[0] === 'outside-auth') {
        let redirect = actualRoute[1].split('confirm-auth?');
        window.localStorage.setItem('bc_redirect_auth', redirect[1]);
      }
      
      this.salvaTemaPortal();
      this.resetLoading();
    }
  }

  verificaActualRoute() {
    let actualRoute = this.location.path().substring(1).split('/');
    if(actualRoute[0] === 'outside-auth') {
      let redirect = actualRoute[1].split('confirm-auth?');
      window.localStorage.setItem('bc_redirect_auth', redirect[1]);
      this.redirecionaTelaLogin();
    } else {
      if (actualRoute.length === 4 && actualRoute[3] === 'true') {
        this.router.navigateByUrl(this.location.path().substring(1));
        this.resetLoading();
      } else {
        this.redirecionaTelaLogin();
      }
    }
  }

  verificaLoadingTravado(): void {
    clearInterval(this.intervalLoading);
    this.intervalLoading = setInterval(() => {
      if (this.totalRequests !== 0) {
        this.totalRequests = 0;
        this.spinner.hide();
      }
      clearInterval(this.intervalLoading);
    }, 3000);
  }
}

export default Interceptor;