import { ModalConfigFiltrosComponent } from './../modais/modal-config-filtros/modal-config-filtros.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router, Event, NavigationEnd, ActivatedRoute } from "@angular/router";
import { DOCUMENT, Location } from "@angular/common";
import { TokenService } from "src/app/core/token/token.service";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { Usuario } from "src/app/models/usuario.model";
import { UsuariosService } from "src/app/services/usuarios.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AlertasService } from "src/app/services/alertas.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalTarefaComponent } from "../modais/modal-tarefa/modal-tarefa.component";
import { ModalAberturaTicketComponent } from "../modais/modal-abertura-ticket/modal-abertura-ticket.component";
import { MinhaFaturaPlanoComponent } from "../modais/minha-fatura-plano/minha-fatura-plano.component";
import { ChatViewerComponent } from "../modais/chat-viewer/chat-viewer.component";
import { SimpleCrypt } from "ngx-simple-crypt";
import { ChatService } from "src/app/services/chat.service";
import { UntypedFormControl, Validators } from '@angular/forms';
import { WebsocketService } from "src/app/core/websocket/websocket.service";
import { LoginService } from "src/app/services/login.service";
import { Subscription } from "rxjs";
import { WhatsappService } from "src/app/services/whatsapp.service";
import { ThemeService } from "src/app/shared/themes/theme.service";
import { LocalstorageService } from 'src/app/core/localstorage/localstorage.service';
import { AgentesService } from 'src/app/services/agentes.service';
import { RouteInfo, SidebarRoutes } from 'src/app/models/sidebar-routes.model';
import { ModalIndicacaoComponent } from '../modais/modal-indicacao/modal-indicacao.component';
import { ModalFeatureBaseComponent } from '../modais/modal-feature-base/modal-feature-base.component';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private organization = 'yourorg'; // Substitua pelo nome da organização
  private theme = 'light'; // Ou 'dark'
  private initialPage = 'Board'; // 'Board', 'Changelog', 'Roadmap', ou 'Help'
  newSubs!: Subscription;
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  public usuario: Usuario = this.tokenService.getUsuario();
  notificacoesLista = [];
  notificacoesAllLista = [];
  mostraTodasNotificacoes = false;
  dropNotOpen = false;
  permissao = {
    VisualizarChat: this.tokenService.checkPermissions('@VisualizarChat'),
    VisualizarTickets: this.tokenService.checkPermissions('@VisualizarTickets'),
    VisualizarChamados: this.tokenService.checkPermissions('@VisualizarChamados'),
    VisualizarAlertasServidores: this.tokenService.checkPermissions('@VisualizarAlertasServidores'),
    VisualizarAlertasTerminais: this.tokenService.checkPermissions('@VisualizarAlertasTerminais'),
    VisualizarAlertasMobile: this.tokenService.checkPermissions('@VisualizarAlertasMobile'),
    VisualizarPesquisaGlobal: this.tokenService.checkPermissions("@VisualizarPesquisaGlobal")
  };
  modo_tv = false;
  notActive = false;
  globalSearchControl = new UntypedFormControl('');
  selector: string;
  status_empresa_id;
  data_bloqueio_conta;
  possui_faturas_atrasadas: boolean = false;
  novaMensagem = false;
  obterIntegracao = {
    status_id: 0,
    nova_mensagem: false,
    possui_integracao: false,
  };
  contadores: any = {
    ag_atendimento: 0,
    atendendo: 0,
    finalizado: 0,
  };
  realTimeSocket: any;
  pagination: any;
  possuiNotificacoes: boolean = false;
  integracao_erro: boolean = false;
  tema: any;
  avisos: any[];
  visualizaConfigFiltros: boolean = false;
  chatOpened: boolean = false;
  filterNotificationsType: string = "";
  ROUTES: RouteInfo[] = new SidebarRoutes(this.tokenService).rotasPorPerfil();
  tipoPerfilAcesso: number | null = this.tokenService.getTipoPerfilUsuario();
  msgInadimplencia: string = '';

  constructor(
    location: Location,
    private router: Router,
    private tokenService: TokenService,
    public translate: TranslateService,
    private usuariosService: UsuariosService,
    private toastr: ToastrService,
    private alertasService: AlertasService,
    private modalService: NgbModal,
    private chatService: ChatService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private elemRef: ElementRef,
    private websocketService: WebsocketService,
    private loginService: LoginService,
    private whatsappService: WhatsappService,
    private themeService: ThemeService,
    private zone: NgZone,
    private storageService: LocalstorageService,
    private _renderer: Renderer2,
    private agenteService: AgentesService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.selector = this.elemRef.nativeElement.tagName.toLowerCase();
    this.newSubs = this.route.queryParams.subscribe(
      params => {
        this.modo_tv = params['modo-tv'] !== undefined ? true : false
      }
    )
    this.location = location;
    this.newSubs = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.verificaVisualizaConfigFiltros();

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }
    });

    this.getEventTradeLogin();
  }

  checkIframeLoaded() {
    // Get a handle to the iframe element
    let iframe = (document?.querySelector('#iframe_chat') as HTMLIFrameElement)
  }


  private resetFocusOnWindow() {
    setTimeout(() => {
      window.focus();
    }, 100);
  }

  private onWindowBlur() {
    this.checkIframeLoaded()
    this.resetFocusOnWindow();
  }

  checkAgAtendimento() {
    return this.contadores && this.contadores.ag_atendimento && this.contadores.ag_atendimento > 0 ? true : false;
  }

  ngOnInit() {
    this.verifyToken(this.tokenService.getToken());
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
    document.removeEventListener('login-automatic-success', this.getEventTradeLogin);
  }

  verifyToken(_token): void {
    if (_token) {
      this.tema = this.themeService.getActiveTheme().name;
      this.checkOpenChat()
      this.getSituacaoEmpresa(_token)
      this.listTitles = this.ROUTES.filter((listTitle) => listTitle);
      this.listaNotificacoes();

      this.getAvisos(_token)
      // this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.getAvisos(_token)
      }, 300000);

      setTimeout(() => {
        this.changeDetector.detectChanges();
      }, 3500);

      setTimeout(() => {
        this.getSocket();
      }, 1500);
      this.verificaStatusChat();
      // });

      this.verificaIntegracaoWhatsapp();
      this.verificaVisualizaConfigFiltros();
    }
  }

  getEventTradeLogin(): void {
    document.addEventListener("login-automatic-success", (event: CustomEvent) => {
      if (event.detail) {
        this.tokenService.setToken(event.detail);
        this.usuario = this.tokenService.getUsuario();
        this.verifyToken(this.tokenService.getToken());
      }
    });
  }

  toggleTheme(): void {
    switch (this.tema) {
      case 'Light':
        this.themeService.setDarkTheme();
        this.tema = 'Dark';
        break;
      case 'Dark':
        this.themeService.setLightTheme();
        this.tema = 'Light';
        break;
      default:
        break;
    }
  }

  verificaPermissaoOrigem(origem: string): boolean {
    const permissoes: { [key: string]: any; } = {
      'Terminal': this.permissao.VisualizarAlertasTerminais,
      'Servidor': this.permissao.VisualizarAlertasServidores,
      'Mobile': this.permissao.VisualizarAlertasMobile,
    }

    return permissoes[origem]
  }

  verificaTodasPermissoes(): boolean {
    return this.permissao.VisualizarAlertasTerminais || this.permissao.VisualizarAlertasServidores || this.permissao.VisualizarAlertasMobile;
  }

  getSituacaoEmpresa(token) {
    this.loginService.getSituacaoEmpresa(token)
      .subscribe(resultsEmpresa => {
        this.status_empresa_id = resultsEmpresa.empresaSituacao.tipo_situacao_id.toString();
        this.msgInadimplencia = resultsEmpresa.empresaSituacao.mensagem;

        if(((this.status_empresa_id === '2' || this.status_empresa_id === '3') && this.msgInadimplencia) || this.status_empresa_id === '4') {
          this.themeService.emiteMessageInadimplencia('hasMsgIndp');
        }

        if (resultsEmpresa.empresaSituacao.tipo_situacao_id === 3 || resultsEmpresa.empresaSituacao.tipo_situacao_id === 2) {
          if (resultsEmpresa.empresaSituacao.data_bloqueio === null) {
            this.buscaDataBloqueio(token);
          } else {
            this.data_bloqueio_conta = resultsEmpresa.empresaSituacao.data_bloqueio;

            if (this.data_bloqueio_conta && this.data_bloqueio_conta.includes("/")) {
              this.data_bloqueio_conta = this.formatDate(this.data_bloqueio_conta)
            }
          }
        }
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  buscaDataBloqueio(token) {
    this.loginService.buscaDataBloqueio(token).subscribe(
      (result) => {
        this.data_bloqueio_conta = result.data_bloqueio
        if (this.data_bloqueio_conta && this.data_bloqueio_conta.includes("/")) {
          this.data_bloqueio_conta = this.formatDate(this.data_bloqueio_conta)
        }
      }, (error) => {
        console.log(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      }
    )
  }

  checkContadores(data) {
    if (data && data.contadores) {
      this.changeDetector.detectChanges();
      this.contadores = data.contadores;
      this.verificaNovaMensagem()
    }
  }

  getSocket() {
    var _this = this;
    this.realTimeSocket = this.websocketService.getSocket();

    if (this.realTimeSocket) {
      this.realTimeSocket.on("nova_conversa", function (data) {
        _this.checkContadores(data);
        document.dispatchEvent(new CustomEvent("nova_conversa", { 'detail': data }));
      });

      this.realTimeSocket.on("nova_mensagem_chat_client", function (data) {
        _this.checkContadores(data);
        document.dispatchEvent(new CustomEvent("nova_mensagem_chat_client", { 'detail': data }));
      });

      this.realTimeSocket.on("atualizacao_chat", function (data) {
        _this.checkContadores(data);
        document.dispatchEvent(new CustomEvent("atualizacao_chat", { 'detail': data }));
      });

      this.realTimeSocket.on("chat_finalizado", function (data) {
        _this.checkContadores(data);
        document.dispatchEvent(new CustomEvent("chat_finalizado", { 'detail': data }));
      });

      this.realTimeSocket.on("receive", function (data) {
        _this.checkContadores(data);
        document.dispatchEvent(new CustomEvent("receive", { 'detail': data }));
      });
    }

  }

  loadContadores() {
    this.newSubs = this.chatService.getContadores()
      .subscribe((rt) => {
        this.contadores = rt;
        this.verificaNovaMensagem()
      })
  }

  formatDate(data) {
    return data.split('/')[1] + "-" + data.split('/')[0] + "-" + data.split('/')[2]
  }

  verificaStatusChat(): void {
    this.newSubs = this.chatService.obterIntegracao().subscribe(
      (results) => {
        if (results) {
          this.notActive = (results && results.status_id === 1) ? false : true;
          this.obterIntegracao.status_id = (results === null) ? 4 : results.status_id;

          localStorage.removeItem('possui_integracao')
          let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", results.hasOwnProperty('id'))
          localStorage.setItem('possui_integracao', encodedObjectAsString);
          this.loadContadores();
        }
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  verificaNovaMensagem() {
    if (localStorage.getItem('nova_mensagem')) {
      localStorage.removeItem('nova_mensagem')
    }

    if (this.contadores && this.contadores.ag_atendimento) {
      let encodedObjectAsString: string = SimpleCrypt.encodeDefault("szHYxd7tz", this.contadores && this.contadores.ag_atendimento > 0 ? 'true' : 'false')
      localStorage.setItem('nova_mensagem', encodedObjectAsString)
      let decode: {} = SimpleCrypt.decodeDefault("szHYxd7tz", localStorage.getItem('nova_mensagem'));
      const params = JSON.parse(JSON.stringify(decode));
      this.obterIntegracao.nova_mensagem = params.toString() === 'true' ? true : false;
    } else {
      this.obterIntegracao.nova_mensagem = false;
    }
  }

  verificaIntegracaoWhatsapp() {
    this.newSubs = this.whatsappService.testarTodasIntegracoes()
      .subscribe(results => {
        this.integracao_erro = results.possuiProblema;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
      })
  }

  permissaoVisualizar(chave): boolean {
    if (chave !== undefined) {
      return this.tokenService.checkPermissions(chave);
    } else {
      return true;
    }
  }

  verificaDropAberto(tipo): boolean {
    if (tipo === "bell") {
      var _drop = document.getElementById("notificacaoDrop");

      if (_drop !== null && _drop.classList.contains("show")) {
        return true;
      } else {
        return false;
      }
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  verifySidenavStatus(): boolean {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      return true;
    } else {
      return false;
    }
  }

  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  logout() {
    swal
      .fire({
        title: this.translate.instant("DESEJA_SAIR_DO_SISTEMA"),
        text: this.translate.instant("REDIRECIONADO_TELA_LOGIN"),
        icon: "question",
        focusConfirm: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant("SIM"),
        cancelButtonText: this.translate.instant("NAO"),
      })
      .then((result) => {
        if (result.value) {
          this.storageService.logoutWithLocalData();


        }
      });
  }

  removerChats() {
    const iframes = document.querySelectorAll('iframe');
    const scriptChat = document.getElementById('script_widget');

    if (scriptChat) {
      this._renderer.removeChild(document, scriptChat);
    }

    iframes.forEach((iframe: HTMLIFrameElement) => {
      if (iframe.id === 'iframe_chat') {
        this._renderer.removeChild(document, iframe);
      }
      if (iframe.src.includes('button-toggle-chat')) {
        this._renderer.removeChild(document, iframe);
      }
    });
  }

  checkIsOnline(event) {
    this.usuario.is_online_chat = event;
    this.atualizarStatus();
  }

  checkAlertaChat(event) {
    this.usuario.alerta_chat = event;
    this.atualizarStatus();
  }

  atualizarStatus(): void {
    var status = {
      is_online: this.usuario.is_online_chat,
      alerta_chat: this.usuario.alerta_chat,
    };

    this.newSubs = this.usuariosService.atualizarStatusChat(status).subscribe(
      (result) => {
        this.toastr.show(
          this.translate.instant("SALVO_SUCESSO"),
          "",
          environment.toastrConfig("success")
        );
        this.tokenService.setToken(this.usuario);
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  getContadoresAlertas() {
    this.newSubs = this.alertasService.getContadorAlertas().subscribe(
      (results) => {
        this.possuiNotificacoes = results.contador > 0 ? true : false;
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    );
  }

  listaNotificacoes(origin?): void {
    var _pagination = { ...environment.getPaginationDetalhes() }
    if (origin) {
      this.filterNotificationsType = this.filterNotificationsType !== origin
        ? origin
        : "";
    }

    let filter;
    if (this.filterNotificationsType) {
      switch (this.filterNotificationsType) {
        case 'servidor':
          filter = { origemAlerta: 1 };
          break;
        case 'desktop':
          filter = { origemAlerta: 5 };
          break;
        case 'mobile':
          filter = { origemAlerta: 3 };
          break;
        case 'integracao':
          filter = { origemAlerta: 6 };
          break;
      };
    }

    this.newSubs = this.alertasService.getListagemAlertas(_pagination, filter).subscribe(
      (results) => {
        this.notificacoesAllLista = results.lista;
        this.pagination = results.meta.paginate;
        this.notificacoesLista = results.lista;
        this.possuiNotificacoes = results.contador > 0 ? true : false;

        this.possui.servidor = this.verificaSeTemEssaNotificacao('Servidor');
        this.possui.terminal = this.verificaSeTemEssaNotificacao('Terminal');
        this.possui.mobile = this.verificaSeTemEssaNotificacao('Mobile');
        this.possui.integracao = this.verificaSeTemEssaNotificacao('Integrações');
        this.changeDetector.detectChanges();
      },
      (error) => {
        console.log(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
        this.changeDetector.detectChanges();
      }
    );
  }

  setListaByPermissao(lista) {
    var aux = []
    lista.map((element) => {
      if (this.verificaPermissaoOrigem(element.origem)) {
        aux.push(element);
      }
    })

    return aux;
  }

  setTooltipDataAteOMomento(data_alerta) {
    return data_alerta
  }

  notificacoesSemPaginacao(): void {
    this.router.navigate(['/devices/alert-listing'])
  }

  verificaSeTemEssaNotificacao(tipo) {
    var _possui = false;
    this.notificacoesAllLista.forEach((notificacao) => {
      if (notificacao.origem === tipo) {
        _possui = true;
      }
    });

    return _possui
  }

  abrirModalNovaTarefa(): void {
    const modalRef = this.modalService.open(ModalTarefaComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "grande",
      keyboard: false,
      backdrop: 'static'
    });

    modalRef.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => { }
    );
  }

  abrirModalNovoTicket(notificacao): void {
    const modalRef = this.modalService.open(ModalAberturaTicketComponent, {
      backdrop: "static",
      windowClass: "modal-custom-milvus",
      keyboard: false,
      scrollable: false,
      size: "grande-encerrar-chat",
    });

    modalRef.componentInstance.abrir_ticket = notificacao;

    modalRef.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => { }
    );
  }

  abrirModalMinhaFatura(): void {
    const modalRef = this.modalService.open(MinhaFaturaPlanoComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "grande",
      keyboard: false,
      backdrop: "static",
      centered: true
    });

    modalRef.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => { }
    );
  }

  abrirModalChat(): void {
    const modalRef = this.modalService.open(ChatViewerComponent,
      {
        scrollable: false,
        windowClass: 'modal-custom-milvus',
        size: 'full-screen',
        keyboard: false,
        backdrop: 'static',
        centered: true
      });

    modalRef.result.then((result) => {

    }, (reason) => {
    });
  }

  splitName(name): string {
    var _nome = name.split(' ');
    if (_nome.length > 1) {
      return _nome[0].charAt(0) + _nome[1].charAt(0);
    } else {
      return name.charAt(0);
    }
  }

  possui = {
    servidor: false,
    terminal: false,
    mobile: false,
    integracao: false
  }

  marcaTodosComoLido() {
    this.newSubs = this.alertasService.marcarAlertaComoLido({
      "alerta": {
        "todos_alertas": true
      }
    }).subscribe(
      (result) => {
        if (result.status_code === 200) {
          this.notificacoesAllLista = [];
          this.notificacoesLista = [];
          this.possuiNotificacoes = false;
          this.possui.mobile = false;
          this.possui.servidor = false;
          this.possui.terminal = false;
        }
      }, (error) => {
        console.log(error);
        this.toastr.show(error, "", environment.toastrConfig("danger"));
      }
    )
  }

  search() {
    let searchString = this.globalSearchControl.value
    if (searchString !== "" && searchString !== undefined) {
      if (localStorage.getItem('empresa_situacao_id')) {
        if (localStorage.getItem('empresa_situacao_id') !== '3') {
          this.router.navigate(['/global-search/', searchString]);
        } else {
          this.router.navigate(['/user-area/plans']);
        }
      }
    }
  }

  redirectAssinarPlano() {
    this.router.navigate(['trial/free-trial-ended']);
  }

  getAvisos(token) {
    if (token) {
      this.newSubs = this.alertasService.buscarAvisos(token).subscribe(
        (result) => {
          this.changeDetector.detectChanges();
          if (result.avisos.length > 0) {
            if (localStorage.getItem('avisosLidos')) {
              let dismissedHistory: any[] = JSON.parse(localStorage.getItem('avisosLidos')!)
              result.avisos = result.avisos.filter(alerta => !dismissedHistory.some(a => { return a.id === alerta.id }))
              this.avisos = result.avisos
            } else {
              this.avisos = result.avisos
            }
          }
        }, (error) => {
          console.log(error);
        }
      )
    }
  }

  onClosed(dismissedAlert: any): void {
    let readAlert = []
    if (localStorage.getItem('avisosLidos')) {
      readAlert = JSON.parse(localStorage.getItem('avisosLidos')!)
    }
    this.avisos = this.avisos.filter(alert => {
      if (alert === dismissedAlert) readAlert.push(alert)
      return alert !== dismissedAlert
    });
    localStorage.setItem('avisosLidos', JSON.stringify(readAlert))
  }

  abrirModalConfigFiltros(): void {
    const modalRef = this.modalService.open(ModalConfigFiltrosComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "medio",
      centered: true
    });

    modalRef.result.then(
      (result) => {
        if (result) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([this.location.path().substring(1)]);
          });
        }
      },
      (reason) => { }
    );
  }

  verificaVisualizaConfigFiltros(): void {
    if (environment.allowFiltroNovo) {
      let actualRoute = this.router.url;
      if (actualRoute === '/tickets/list' || actualRoute === '/dashboards/dashboard') {
        this.visualizaConfigFiltros = true;
        this.changeDetector.detectChanges();
      } else {
        this.visualizaConfigFiltros = false;
        this.changeDetector.detectChanges();
      }
    } else {
      this.visualizaConfigFiltros = false;
    }
  }

  openChatWidget(): void {
    this.chatOpened = !this.chatOpened;
    if (this.chatOpened) {
      document.dispatchEvent(new Event('openButton'));
    } else {
      document.dispatchEvent(new Event('closeButton'));
    }
  }

  checkOpenChat() {
    document.addEventListener('detectChangeChat', () => {
      var _auxCheck = (localStorage.getItem('openChatTest') && localStorage.getItem('openChatTest').toString() === 'true') ? true : false;
      this.chatOpened = _auxCheck;
      this.changeDetector.detectChanges();
    })
  }

  featureBase() {
    const usuario = this.tokenService.getUsuario();
    let data = {
      empresa: {
        nome_fantasia: usuario.empresa.nome_fantasia
      }
    }
    this.newSubs = this.usuariosService.acessarFeatureBase(data)
      .subscribe({
        next: resp => {
          if (resp) {
            this.featureBaseModalSDK(resp.link)
          }
        }
      })
  }

  featureBaseModalSDK(url: string) {
    const modalRef = this.modalService.open(ModalFeatureBaseComponent, {
      scrollable: false,
      windowClass: "modal-custom-iframe modal-custom-milvus",
      size: "xl",
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.url = url
    modalRef.componentInstance.header = 'ABERTURA_DE_SUGESTOES'
  }

  abrirModalIndicacao(): void {
    const modalRef = this.modalService.open(ModalIndicacaoComponent, {
      scrollable: false,
      windowClass: "modal-custom-milvus",
      size: "grande",
      keyboard: false,
      backdrop: 'static'
    });
  }
}
