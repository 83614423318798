import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterModule, Router } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ToastrModule } from "ngx-toastr";
import { TagInputModule } from "ngx-chips";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { LoginComponent } from "./pages/login/login.component";
import { AngularMaterialModule } from "./shared/angular-material.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { ModalModule } from "./components/modais/modal.module";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { ImageCropperModule } from "ngx-image-cropper";

import { NgxMaskModule, IConfig } from "ngx-mask";
import { TreeviewModule } from "ngx-treeview";
import { CommonModule } from "@angular/common";
import { defineLocale } from "ngx-bootstrap/chronos";
import { ptBrLocale } from "ngx-bootstrap/locale";
defineLocale("pt-br", ptBrLocale);
import { PickerModule } from "@ctrl/ngx-emoji-mart";
import { EsqueceuSenhaComponent } from "./pages/recuperar-senha/esqueceu-senha/esqueceu-senha.component";
import { InterceptorModule } from "src/app/core/interceptor/interceptor.module";
import { BuscaGlobalComponent } from "./pages/busca-global/busca-global.component";
import { AutocompleteOffDirective } from "./autocomplete-off.directive";
import { AutosizeModule } from "ngx-autosize";
import { PreviousRouteService } from "./services/route/previous-route.service";
import * as Sentry from "@sentry/angular";


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
    EsqueceuSenhaComponent,
    AutocompleteOffDirective,
    //BuscaGlobalComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    CollapseModule.forRoot(),
    TagInputModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularMaterialModule,
    NgbModule,
    ModalModule,
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    TreeviewModule.forRoot(),
    MatSlideToggleModule,
    CommonModule,
    MatTabsModule,
    ImageCropperModule,
    PickerModule,
    InterceptorModule,
    AutosizeModule
  ],
  providers: [
    PreviousRouteService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
