<div class="modal-content">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-title-default" [translate]="'INDIQUE_AMIGO'"></h6>
      <button id="btn-fechar-modal-indicacao" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
        <i class="mdi mdi-close"></i>
      </button>
    </div>
    <!-- body -->
    <div class="modal-body">
      <div class="row">
        <div class="col-2">
          <img src="assets/img/general/indique_milvus.png" alt="indicação amigo" >
        </div>
        <div class="col-4 mt-4">
          <span class="fs-5" [translate]="'CONVIDE_AMIGOS'"></span>
          <p class="mt-2"><span [innerHTML]="'DESCRICAO_INDICACAO' | translate"></span></p>
        </div>
        <div class="col-2">
          <span class="fw-bold" [translate]="'VALE_INDICACAO'"></span>
          <img src="assets/img/general/presente.png" alt="indicação amigo" >
        </div>
        <div class="col-4 mt-4">
          <span class="fs-5" [translate]="'COMO_FUNCIONA'"></span>
          <p class="mt-2"><span [innerHTML]="'COMO_FUNCIONA_INDICACAO' | translate"></span></p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-2">
            <div class="form-group">
                <label [translate]="'NOME_INDICADO'"></label>
                <input style="font-size: 12px;"
                 [(ngModel)]="indicacao.nome" 
                 class="form-control" 
                 type="text" 
                 id="nome" 
                 maxlength="100"
                 [ngClass]="{required: verificaRequerido(indicacao.nome)}" >
            </div>
        </div>
        <div class="col-3">
          <div class="form-group">
              <label [translate]="'EMPRESA_INDICADA'"></label>
              <input style="font-size: 12px;" [(ngModel)]="indicacao.empresa" class="form-control" type="text" id="empresa" maxlength="100" [required]="true">
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
              <label [translate]="'EMAIL_INDICADO'"></label>
              <input style="font-size: 12px;" [ngClass]="{required: verificaRequerido(indicacao.email)}" [(ngModel)]="indicacao.email" class="form-control" type="email" id="email" maxlength="100" [required]="true">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
              <label style="font-size: 13px !important;" [translate]="'WPP_INDICADO'"></label>
              <input style="font-size: 12px;" [ngClass]="{required: verificaRequerido(indicacao.telefone)}" [(ngModel)]="indicacao.telefone" class="form-control" type="text" id="whatsapp" maxlength="100" [required]="true">
          </div>
        </div>
        <div class="col-2 mt-4">
          <button class="btn btn-success mt-1" style="font-size: 12px; padding: 0; width: 155px; height: 46px;" [disabled]="verificaSeDesativa()" (click)="enviarIndicacao()" [translate]="'ENVIAR_INDICACAO'"></button>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
            <div class="form-group">
                <textarea style="font-size: 12px;" [rows]="rows" (input)="ajustRows($event)" class="form-control custom-textarea-obs" placeholder="{{ 'INDICACAO_OBSERVACAO' | translate }}" [(ngModel)]="indicacao.observacao" id="observacao"></textarea>
            </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="row" style="margin-top: -6px;">
            <div class="col-12">
                <div class="table-responsive table-listagem" style="overflow-x: hidden !important;">
                    <table class="table align-items-center table-flush">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" style="padding-left: 19px; padding-top: 13px; padding-bottom: 12px;">
                                    <span [translate]="'NOME'"></span>
                                </th>
                                <th scope="col" style="padding-top: 13px; padding-bottom: 12px;">
                                    <span [translate]="'EMPRESA'"></span>
                                </th>
                                <th scope="col" style="padding-top: 13px; padding-bottom: 12px;">
                                    <span [translate]="'EMAIL'"></span>
                                </th>
                                <th scope="col" style="padding-top: 13px; padding-bottom: 12px;">
                                    <span [translate]="'TELEFONE'"></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="list" *ngIf="indicacaoList.length > 0">
                            <tr *ngFor="let indicacao of indicacaoList">
                                <td style="padding-left: 19px;">{{indicacao.nome }}</td>
                                <td>{{indicacao.empresa }}</td>
                                <td>{{indicacao.email }}</td>
                                <td>{{indicacao.telefone }}</td>
                            </tr>
                        </tbody>
                        <tbody class="list" *ngIf="indicacaoList.length === 0">
                            <tr>
                                <td colspan="5" class="text-center">
                                    <h4 [translate]="'NAO_HA_REGISTROS'"></h4>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot></tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>