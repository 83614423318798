<div class="modal-content" style="zoom: 0.85;">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-title-default">
            {{multiplo ? ('SELECIONAR_CONTATO' | translate) : add_via_chat ? contato?.id ? ('EDITAR_CONTATO' | translate) : ('CONTATO' | translate) : contato?.id ? ('EDITAR_CONTATO' | translate) : ('NOVO_CONTATO' | translate)}}
        </h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <form *ngIf="!multiplo; else formMultiplo" [formGroup]="contatoForm">
            <ng-container *ngIf="add_via_chat; else formPadrao">
                <ng-container *ngIf="contato?.id; else criacaoViaChat">
                    <!-- FORM EDIT CONTATO B2B OU B2C -->
                    <ng-container>
                        <div class="row">
                            <div *ngIf="contato?.cliente_id" class="col-6 form-group">
                                <label [translate]="'CLIENTE'"></label>
                                <select-padrao [entidade]="'selectClientes'" [disabled]="true" [returnObj]="true" [model]="contato?.cliente ? contato?.cliente : null" (selectChange)="mudaCliente($event)"></select-padrao>
                            </div>
                            <div [ngClass]="{'col-12': !contato?.cliente_id, 'col-6': contato?.cliente_id}" class="form-group">
                                <label [translate]="'NOME'"></label>
                                <input type="text" formControlName="descricao" class="form-control" [ngClass]="{'required': contatoForm.get('descricao').invalid}" placeholder="{{'NOME' | translate}}">
                            </div>
                        </div>
                        <div class="row">
                            <div [ngClass]="{'col-12': contato?.cliente_id, 'col-6': !contato?.cliente_id}" class="form-group">
                                <label [translate]="'EMAIL'"></label>
                                <input type="text" formControlName="email" class="form-control" placeholder="{{'EMAIL' | translate}}">
                            </div>
                            <div *ngIf="!contato?.cliente_id" class="col-6 form-group">
                                <label>CPF</label>
                                <input type="text" formControlName="cpf" [ngClass]="{'required': contatoForm.get('cpf').invalid}" [mask]="'000.000.000-00'" class="form-control" placeholder="000.000.000-00">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 form-group">
                                <label>DDI</label>
                                <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id ? {id: contato.codigo_pais_id, valor: contato.codigo_pais} : null" [returnObj]="true" [disabled]="contato.codigo_pais_id && contato.codigo_pais_id !== 0 ? true : false" [required]="contatoForm.get('celular').value && contatoForm.get('celular').value.substring(0, 4) !== '0800' ? true : false" (selectChange)="ddiCelular($event)"></select-padrao>
                            </div>
                            <div class="col-3 form-group">
                                <label [translate]="'CELULAR'"></label>
                                <input *ngIf="mask_cel !== ''" type="text" formControlName="celular" [mask]="mask_cel" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                                <input *ngIf="mask_cel === ''" type="text" formControlName="celular" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                            </div>
                            <div class="col-4 form-group">
                                <label>App</label>
                                <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_celular : null" [returnObj]="false" [disabled]="true" [multiple]="true" (selectChange)="appsCelular($event)"></select-padrao>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #criacaoViaChat>
                    <!-- FORM ADD VIA CHAT B2B E B2C -->
                    <ng-container *ngIf="add_via_chat && solicitantes.solicitante_cliente && (solicitantes.solicitante_contato || solicitantes.solicitante_operador)">
                        <div class="row">
                            <div class="col-6 form-group">
                                <label [translate]="'TIPO_CONTATO'"></label>
                                <select-padrao [entidade]="'selectTipoContatoSolicitante'" [required]="true" [model]="contato?.cliente_id ? {id: 1} : contato?.id ? {id: 2} : {id: 1}" (selectChange)="mudaTipoContato($event, true)"></select-padrao>
                            </div>
                            <div class="col-6 form-group">
                                <label [translate]="'CLIENTE'"></label>
                                <select-padrao [entidade]="'selectClientes'" [returnObj]="true" [disabled]="contatoForm.get('tipo_contato_id').value === 2 ? true : false" [required]="contatoForm.get('tipo_contato_id').value === 1 ? true : false" [model]="contato?.cliente ? contato?.cliente : null" (selectChange)="mudaCliente($event)"></select-padrao>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label [translate]="'NOME'"></label>
                                <input type="text" formControlName="descricao" class="form-control" [ngClass]="{'required': contatoForm.get('descricao').invalid}" placeholder="{{'NOME' | translate}}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group">
                                <label [translate]="'EMAIL'"></label>
                                <input type="text" formControlName="email" class="form-control" placeholder="{{'EMAIL' | translate}}">
                            </div>
                            <div class="col-6 form-group">
                                <label>CPF</label>
                                <input type="text" formControlName="cpf" [ngClass]="{'required': contatoForm.get('cpf').invalid}" [mask]="'000.000.000-00'" class="form-control" placeholder="000.000.000-00">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 form-group">
                                <label>DDI</label>
                                <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id ? {id: contato.codigo_pais_id, valor: contato.codigo_pais} : null" [returnObj]="true" [disabled]="contato.codigo_pais_id && contato.codigo_pais_id !== 0 ? true : false" [required]="contatoForm.get('celular').value && contatoForm.get('celular').value.substring(0, 4) !== '0800' ? true : false" (selectChange)="ddiCelular($event)"></select-padrao>
                            </div>
                            <div class="col-3 form-group">
                                <label [translate]="'CELULAR'"></label>
                                <input *ngIf="mask_cel !== ''" type="text" formControlName="celular" [mask]="mask_cel" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                                <input *ngIf="mask_cel === ''" type="text" formControlName="celular" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                            </div>
                            <div class="col-4 form-group">
                                <label>App</label>
                                <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_celular : null" [returnObj]="false" [disabled]="true" [multiple]="true" (selectChange)="appsCelular($event)"></select-padrao>
                            </div>
                        </div>
                    </ng-container>
        
                    <!-- FORM ADD VIA CHAT B2B -->
                    <ng-container *ngIf="add_via_chat && solicitantes.solicitante_cliente && !solicitantes.solicitante_contato && !solicitantes.solicitante_operador">
                        <div class="row">
                            <div class="col-6 form-group">
                                <label [translate]="'CLIENTE'"></label>
                                <select-padrao [entidade]="'selectClientes'" [returnObj]="true" [required]="true" [model]="contato?.cliente ? contato?.cliente : null" (selectChange)="mudaCliente($event)"></select-padrao>
                            </div>
                            <div class="col-6 form-group">
                                <label [translate]="'NOME'"></label>
                                <input type="text" formControlName="descricao" class="form-control" [ngClass]="{'required': contatoForm.get('descricao').invalid}" placeholder="{{'NOME' | translate}}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 form-group">
                                <label [translate]="'EMAIL'"></label>
                                <input type="text" formControlName="email" class="form-control" placeholder="{{'EMAIL' | translate}}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 form-group">
                                <label>DDI</label>
                                <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id ? {id: contato.codigo_pais_id, valor: contato.codigo_pais} : null" [returnObj]="true" [disabled]="contato.codigo_pais_id && contato.codigo_pais_id !== 0 ? true : false" [required]="contatoForm.get('celular').value && contatoForm.get('celular').value.substring(0, 4) !== '0800' ? true : false" (selectChange)="ddiCelular($event)"></select-padrao>
                            </div>
                            <div class="col-3 form-group">
                                <label [translate]="'CELULAR'"></label>
                                <input *ngIf="mask_cel !== ''" type="text" formControlName="celular" [mask]="mask_cel" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                                <input *ngIf="mask_cel === ''" type="text" formControlName="celular" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                            </div>
                            <div class="col-4 form-group">
                                <label>App</label>
                                <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_celular : null" [returnObj]="false" [disabled]="true" [multiple]="true" (selectChange)="appsCelular($event)"></select-padrao>
                            </div>
                        </div>
                    </ng-container>
        
                    <!-- FORM ADD VIA CHAT B2C -->
                    <ng-container *ngIf="add_via_chat && !solicitantes.solicitante_cliente && (solicitantes.solicitante_contato || solicitantes.solicitante_operador)">
                        <div class="row">
                            <div class="col-12 form-group">
                                <label [translate]="'NOME'"></label>
                                <input type="text" formControlName="descricao" class="form-control" [ngClass]="{'required': contatoForm.get('descricao').invalid}" placeholder="{{'NOME' | translate}}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 form-group">
                                <label [translate]="'EMAIL'"></label>
                                <input type="text" formControlName="email" class="form-control" placeholder="{{'EMAIL' | translate}}">
                            </div>
                            <div class="col-6 form-group">
                                <label>CPF</label>
                                <input type="text" formControlName="cpf" [ngClass]="{'required': contatoForm.get('cpf').invalid}" [mask]="'000.000.000-00'" class="form-control" placeholder="000.000.000-00">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 form-group">
                                <label>DDI</label>
                                <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id ? {id: contato.codigo_pais_id, valor: contato.codigo_pais} : null" [returnObj]="true" [disabled]="contato.codigo_pais_id && contato.codigo_pais_id !== 0 ? true : false" [required]="contatoForm.get('celular').value && contatoForm.get('celular').value.substring(0, 4) !== '0800' ? true : false" (selectChange)="ddiCelular($event)"></select-padrao>
                            </div>
                            <div class="col-3 form-group">
                                <label [translate]="'CELULAR'"></label>
                                <input *ngIf="mask_cel !== ''" type="text" formControlName="celular" [mask]="mask_cel" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                                <input *ngIf="mask_cel === ''" type="text" formControlName="celular" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                            </div>
                            <div class="col-4 form-group">
                                <label>App</label>
                                <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_celular : null" [returnObj]="false" [disabled]="true" [multiple]="true" (selectChange)="appsCelular($event)"></select-padrao>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>

            <!-- FORM PADRAO -->
            <ng-template #formPadrao>
                <div *ngIf="solicitantes && solicitantes.solicitante_cliente && (solicitantes.solicitante_contato || solicitantes.solicitante_operador)" class="row">
                    <div class="col-6 form-group">
                        <label [translate]="'TIPO_CONTATO'"></label>
                        <select-padrao [entidade]="'selectTipoContatoSolicitante'" [required]="true" [model]="contato && contato.cliente_id ? {id: 1} : !contato?.id ? {id: 1} : {id: 2}" [disabled]="contato?.id ? true : false" (selectChange)="mudaTipoContato($event)"></select-padrao>
                    </div>
                    <div *ngIf="solicitantes.solicitante_cliente" class="col-6 form-group">
                        <label [translate]="'CLIENTE'"></label>
                        <select-padrao *ngIf="contatoForm.get('tipo_contato_id').value === 1" [entidade]="'selectClientes'" [returnObj]="true" [required]="true" [model]="contato?.cliente ? contato?.cliente : null" (selectChange)="mudaCliente($event)"></select-padrao>
                        <select *ngIf="!contatoForm.get('tipo_contato_id').value || contatoForm.get('tipo_contato_id').value === 2" class="form-control" disabled>
                            <option [translate]="'SELECIONE'" selected></option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="solicitantes && solicitantes.solicitante_cliente && !solicitantes.solicitante_contato && !solicitantes.solicitante_operador && contatoForm.value.tipo_contato_id === 1" class="col-6 form-group">
                        <label [translate]="'CLIENTE'"></label>
                        <select-padrao [entidade]="'selectClientes'" [required]="true" [returnObj]="true" [model]="contato?.cliente ? contato?.cliente : null" [disabled]="contato?.id ? true : false" (selectChange)="mudaCliente($event)"></select-padrao>
                    </div>
                    <div [ngClass]="{'col-12': (solicitantes && solicitantes.solicitante_cliente && (solicitantes.solicitante_contato || solicitantes.solicitante_operador)) || (!solicitantes && !tipo_solicitante_id) || (solicitantes && !solicitantes.solicitante_contato && !solicitantes.solicitante_operador && contatoForm.value.tipo_contato_id === 2 && !tipo_solicitante_id), 'col-8': tipo_solicitante_id, 'col-6': solicitantes && solicitantes.solicitante_cliente && !solicitantes.solicitante_contato && !solicitantes.solicitante_operador}" class="form-group">
                        <label [translate]="'NOME'"></label>
                        <input type="text" formControlName="descricao" class="form-control" [ngClass]="{'required': contatoForm.get('descricao').invalid}" placeholder="{{'NOME' | translate}}">
                    </div>
                    <div *ngIf="(tipo_solicitante_id && tipo_solicitante_id !== 1)" class="col-4 form-group">
                        <label>CPF</label>
                        <input type="text" formControlName="cpf" [ngClass]="{'required': contatoForm.get('cpf').invalid}" [mask]="'000.000.000-00'" class="form-control" placeholder="000.000.000-00">
                    </div>
                    <div *ngIf="tipo_solicitante_id && tipo_solicitante_id === 1" class="col-4 form-group">
                        <label [translate]="'PADRAO'"></label>
                        <select class="form-control" formControlName="is_padrao">
                            <option [translate]="'SIM'" value="true"></option>
                            <option [translate]="'NAO'" value="false"></option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div [ngClass]="{'col-8': (solicitantes && !solicitantes.solicitante_cliente && (solicitantes.solicitante_contato || solicitantes.solicitante_operador)) || contatoForm.value.tipo_contato_id === 2, 'col-12': ((solicitantes && (!solicitantes.solicitante_cliente || !solicitantes.solicitante_contato || !solicitantes.solicitante_operador)) || !solicitantes) && contatoForm.value.tipo_contato_id !== 2}" class="form-group">
                        <label [translate]="'EMAIL'"></label>
                        <input type="text" formControlName="email" class="form-control" placeholder="{{'EMAIL' | translate}}">
                    </div>
                    <div *ngIf="(solicitantes && (!solicitantes.solicitante_cliente && (solicitantes.solicitante_contato || solicitantes.solicitante_operador))) || contatoForm.value.tipo_contato_id === 2" class="col-4 form-group">
                        <label>CPF</label>
                        <input type="text" formControlName="cpf" [ngClass]="{'required': contatoForm.get('cpf').invalid}" [mask]="'000.000.000-00'" class="form-control" placeholder="000.000.000-00">
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 form-group">
                        <label>DDI</label>
                        <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id ? {id: contato.codigo_pais_id, valor: contato.codigo_pais} : null" [returnObj]="true" [required]="contatoForm.get('celular').value && contatoForm.get('celular').value.substring(0, 4) !== '0800' ? true : false" (selectChange)="ddiCelular($event)"></select-padrao>
                    </div>
                    <div class="col-3 form-group">
                        <label [translate]="'CELULAR'"></label>
                        <input *ngIf="mask_cel !== ''" type="text" formControlName="celular" [mask]="mask_cel" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                        <input *ngIf="mask_cel === ''" type="text" formControlName="celular" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                    </div>
                    <div class="col-4 form-group">
                        <label>App</label>
                        <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_celular : null" [returnObj]="false" [multiple]="true" (selectChange)="appsCelular($event)"></select-padrao>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 form-group">
                        <label>DDI</label>
                        <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id_telefone ? {id: contato.codigo_pais_id_telefone, valor: contato.codigo_pais_telefone} : null" [returnObj]="true" [required]="contatoForm.get('telefone').value && contatoForm.get('telefone').value.substring(0, 4) !== '0800' && contatoForm.get('telefone').invalid ? true : false" (selectChange)="ddiTelefone($event)"></select-padrao>
                    </div>
                    <div class="col-3 form-group">
                        <label [translate]="'TELEFONE'"></label>
                        <input *ngIf="mask_tel !== ''" type="text" formControlName="telefone" [mask]="mask_tel" [ngClass]="{'required': (contatoForm.get('telefone').value && contatoForm.get('telefone').value.length < 10)}" class="form-control">
                        <input *ngIf="mask_tel === ''" type="text" formControlName="telefone" [ngClass]="{'required': (contatoForm.get('telefone').value && contatoForm.get('telefone').value.length < 10)}" class="form-control">
                    </div>
                    <div class="col-4 form-group">
                        <label>App</label>
                        <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_telefone : null" [returnObj]="false" [multiple]="true" (selectChange)="appsTelefone($event)"></select-padrao>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label [translate]="'OBSERVACAO'"></label>
                        <ckeditor [editor]="Editor" class="expanded" [config]="editorConfig" formControlName="observacao" (ready)="onEditorReady($event)">
                        </ckeditor>
                    </div>
                </div>
            </ng-template>
        </form>
        <ng-template #formMultiplo>
            <form [formGroup]="multiploForm">
                <div class="row">
                    <div class="col-12 form-group">
                        <label [translate]="'CLIENTE'"></label>
                        <select-padrao [entidade]="'customContent'" [params]="multiplo.clientes" [model]="contato?.cliente_id ? {id: contato.cliente_id} : multiplo.clientes.length === 1 ? {id: multiplo.clientes[0].id} : null" [required]="true" [returnObj]="true" (selectChange)="mudaCliente($event)"></select-padrao>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 form-group">
                        <label [translate]="'SELECIONE_CONTATO_CADASTRADO_CRIAR_NOVO'"></label>
                        <select-padrao [entidade]="'customContent'" [params]="dropdownContatos" [model]="dropdownContatos.length === 1 ? {id: dropdownContatos[0].id} : null" [required]="true" [returnObj]="false" (selectChange)="multiploForm.patchValue({contato_selecionado_id: $event}); verificaCampoCpf($event)"></select-padrao>
                    </div>
                </div>
            </form>
            <form *ngIf="multiploForm.get('contato_selecionado_id').value === '__add__'" [formGroup]="contatoForm">
                <div class="row">
                    <div class="col-12 form-group">
                        <label [translate]="'NOME'"></label>
                        <input type="text" formControlName="descricao" class="form-control" [ngClass]="{'required': contatoForm.get('descricao').invalid}" placeholder="{{'NOME' | translate}}">
                    </div>
                </div>
                <div class="row">
                    <div [ngClass]="{'col-6': multiploForm.get('cliente_id').value?.is_sem_cliente, 'col-12': !multiploForm.get('cliente_id').value?.is_sem_cliente}" class="form-group">
                        <label [translate]="'EMAIL'"></label>
                        <input type="text" formControlName="email" class="form-control" placeholder="{{'EMAIL' | translate}}">
                    </div>
                    <div *ngIf="multiploForm.get('cliente_id').value?.is_sem_cliente" class="col-6 form-group">
                        <label>CPF</label>
                        <input type="text" formControlName="cpf" [ngClass]="{'required': contatoForm.get('cpf').invalid}" [mask]="'000.000.000-00'" class="form-control" placeholder="000.000.000-00">
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 form-group">
                        <label>DDI</label>
                        <select-padrao [entidade]="'selectCodigoDdiPais'" [model]="contato && contato.codigo_pais_id ? {id: contato.codigo_pais_id, valor: contato.codigo_pais} : null" [returnObj]="true" [disabled]="contato.codigo_pais_id && contato.codigo_pais_id !== 0 ? true : false" [required]="contatoForm.get('celular').value && contatoForm.get('celular').value.substring(0, 4) !== '0800' ? true : false" (selectChange)="ddiCelular($event)"></select-padrao>
                    </div>
                    <div class="col-3 form-group">
                        <label [translate]="'CELULAR'"></label>
                        <input *ngIf="mask_cel !== ''" type="text" formControlName="celular" [mask]="mask_cel" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                        <input *ngIf="mask_cel === ''" type="text" formControlName="celular" [dropSpecialCharacters]="true" [ngClass]="{'required': (contatoForm.get('celular').value && contatoForm.get('celular').value.length < 10)}" class="form-control">
                    </div>
                    <div class="col-4 form-group">
                        <label>App</label>
                        <select-padrao [entidade]="'selectAppsCadastroContato'" [model]="contato ? contato.app_celular : null" [returnObj]="false" [disabled]="true" [multiple]="true" (selectChange)="appsCelular($event)"></select-padrao>
                    </div>
                </div>
            </form>
        </ng-template>
    </div>
    <div class="modal-footer">
        <ng-container *ngIf="!multiplo; else footerMultiplo">
            <button type="button" class="btn btn-success badge-pill" (click)="salvarContato()">
                <i class="fas fa-save"></i> {{'SALVAR' | translate}}
            </button>
            <button *ngIf="origem_chat" type="button" class="btn btn-darkblue badge-pill" (click)="salvarContato(true)" [disabled]="contatoForm.invalid">
                <i class="fas fa-save"></i> {{'SALVAR_CONVERSAR' | translate}}
            </button>
        </ng-container>
        <ng-template #footerMultiplo>
            <button type="button" class="btn btn-success badge-pill" (click)="salvarContato()" [disabled]="verificaDesabilitaFormMultiplo()">
                <i class="fas fa-save"></i> {{'SALVAR' | translate}}
            </button>
        </ng-template>
    </div>
</div>