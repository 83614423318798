import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputHorasDirective } from './input-horas.directive';
import { HoursminutesPipe } from '../validators/pipes/hoursminutes.pipe';
import { InputMoedaDirective } from './input-moeda.directive';
import { HtmlInterpreterDirective } from './html-interpreter.directive';
import { OnlyIntegerDirective } from './only-integer.directive';
import { DndDirective } from 'src/app/components/modais/modal-abertura-ticket/dnd.directive';
import { ValidarPermissaoDirective } from './validar-permissao.directive';


@NgModule({
  declarations: [
    InputHorasDirective,
    HoursminutesPipe,
    InputMoedaDirective,
    HtmlInterpreterDirective,
    OnlyIntegerDirective,
    DndDirective,
    ValidarPermissaoDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InputHorasDirective,
    HoursminutesPipe,
    InputMoedaDirective,
    HtmlInterpreterDirective,
    OnlyIntegerDirective,
    DndDirective,
    ValidarPermissaoDirective
  ],
  providers: [
    HoursminutesPipe,
  ]
})
export class DirectivesModule { }
