import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from '../core/token/token.service';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  private apiUrl = environment.apiGestorUrlLocal;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'authorization': this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
    params: {}
  };
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getValor(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp/obter-valor`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  aceitarRecusarIntegracao(value): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/${value}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterIntegracao(params?): Observable<any> {
    this.httpOptions.params = (params) ? params : '';
    return this.http.get(`${this.apiUrl}api/whatsapp/obter-integracao`, {
      headers: this.httpOptions.headers,
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  getHistorico(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/whatsapp/historico`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  autenticarNumero(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/autenticar-numero`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }
  
  desconectarNumero(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/whatsapp/desconectar-integracao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  testarServidor(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp/testar-servidor`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  mensagemTeste(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/testar-mensagem`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  emailMsgErro(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/enviar-email`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  gerarScript(id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp/gerar-script/${id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateConfigGeral(payload, id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/whatsapp/configuracao-geral/${id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removeConfig(params?): Observable<any> {
    this.httpOptions.params = (params) ? params : '';
    return this.http.delete(`${this.apiUrl}api/whatsapp/remover-integracao`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getListagemNumerosWpp(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/whatsapp/obter-integracao-listagem`, filtros, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  criarIntegracaoWpp(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/criar-integracao`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletarNumeroWpp(integracao_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.delete(`${this.apiUrl}api/whatsapp/remover-integracao-id/${integracao_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  obterIntegracaoById(integracao_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp/obter-integracao-id/${integracao_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  autenticarNumById(integracao_id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/autenticar-numero-id/${integracao_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  desconectarNumById(integracao_id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/whatsapp/desconectar-integracao-id/${integracao_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  testarMensagemById(integracao_id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp/testar-mensagem-id/${integracao_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  testarTodasIntegracoes(): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp/testar-integracoes`, {
      headers: this.httpOptions.headers,
      params: {}
    })
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialCreateIntegration(): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/whatsapp-oficial/criar-integracao`, null, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wpOfficialAuthenticateNumberID(payload, integration_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp-oficial/autenticar-numero-id/${integration_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialGetIntegration(integration_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp-oficial/obter-integracao-id/${integration_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialDisconnectNumber(integration_id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.put(`${this.apiUrl}api/whatsapp-oficial/desconectar-integracao-id/${integration_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialTestServer(integration_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp-oficial/testar-servidor/${integration_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialGetTemplates(integration_id): Observable<any> {
    this.httpOptions.params = '';
    return this.http.get(`${this.apiUrl}api/whatsapp-oficial/obter-templates/${integration_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialSendEmail(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp-oficial/enviar-email`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialGetHistoryList(params, filters): Observable<any> {
    this.httpOptions.params = params;
    return this.http.post(`${this.apiUrl}api/whatsapp-oficial/historico`, filters, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialSendTestMsg(integration_id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp-oficial/testar-mensagem-id/${integration_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppOfficialStartChat(integration_id, payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/whatsapp-oficial/iniciar-conversa-whatsapp/${integration_id}`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  wppIniciarConversaUnificado(payload): Observable<any> {
    this.httpOptions.params = '';
    return this.http.post(`${this.apiUrl}api/chat/iniciar-conversa-whatsapp-chat`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('Ops! Ocorreu um erro:', error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
        `o body é: ${error.error}`);
    }
    return throwError(
      error.error);
  }
}
