import { Component, HostListener, Inject, OnInit, Renderer2 } from "@angular/core";
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { TokenService } from "./core/token/token.service";
import { Subscription } from "rxjs";
import { SimpleCrypt } from "ngx-simple-crypt";
import { ThemeService } from "./shared/themes/theme.service";
import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./services/authentication.service";

export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  rotaIniciada;
  isAuth = false;
  isMobileResolution: boolean;
  subscription: Subscription;
  show2FAInput: boolean = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private tokenService: TokenService,
    private themeService: ThemeService,
    private authService: AuthenticationService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {

    this.translate.addLangs(['pt', 'es', 'en']);
    this.translate.setDefaultLang('pt');
    if (this.tokenService.getUsuario()) {
      this.translate.use(this.translate.getLangs()[this.tokenService.getUsuario().idioma - 1]);
    }

    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }

    this.themeService.currentMessageInadimplencia.subscribe(msg => {
      if (msg) {
        this.recuoPaginasAlerta();
      }
    });
  }

  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit(): void {
    this.setTheme();

    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.verificaAuth(event);

        if (localStorage.getItem('modo_tv')) {
          let decode: any = SimpleCrypt.decodeDefault("szHYxd7tz", localStorage.getItem("modo_tv"))
          browserRefresh = !this.router.navigated && decode;
        }
      }
    });

    this.openChatWidget();
    this.addNPSScript();
  }


  getIndexIframeChat(collection, _body) {
    Array.from(collection).forEach((element: any, index) => {
      if (element === document.querySelector('#iframe_chat')) {
        /* _body.removeChild(collection[index+1]);       
        _body.removeChild(element);  */
        if (collection[index + 1]) collection[index + 1].remove();
        if (element) element.remove();
      }
    })
  }

  openChatWidget() {
    if (!this.tokenService.hasToken()) {
      let _scriptWidget = this._renderer2.createElement('script');
      _scriptWidget.type = 'application/javascript';
      _scriptWidget.id = 'script_widget';
      _scriptWidget.src = `${environment.scriptChatExterno}`;
      this._renderer2.appendChild(this._document.body, _scriptWidget);

      let _initWidget = this._renderer2.createElement('script');
      _initWidget.type = 'application/javascript';
      _initWidget.id = 'init_widget';
      _initWidget.text = `window.onload = function () {
        chatMilvus.init({
          email: "",
          nome: "",
          country: "+55",
          telefone: ""
        });
      };`;
      this._renderer2.appendChild(this._document.body, _initWidget);
    }

    document.addEventListener('login-automatic-success', () => {
      let _script_widget = document.getElementById('script_widget');
      let _script_init = document.getElementById('init_widget');
      let _iframes = document.getElementsByTagName('iframe');
      let _body = document.getElementById('body-milvus');

      _script_widget.remove();
      _script_init.remove();
      if (_iframes[0]) _iframes[0].remove();
      if (_iframes[0]) _iframes[0].remove();
    });

    document.addEventListener('openButton', () => {
      const usuarioLogado = this.tokenService.getUsuario();

      let _scriptWidget2 = this._renderer2.createElement('script');
      _scriptWidget2.type = 'application/javascript';
      _scriptWidget2.id = 'script_widget';
      _scriptWidget2.src = `${environment.scriptChatExterno}`;
      this._renderer2.appendChild(this._document.body, _scriptWidget2);

      let _initWidget2 = this._renderer2.createElement('script');
      _initWidget2.type = 'application/javascript';
      _initWidget2.id = 'init_widget';
      _initWidget2.text = `
        setTimeout(function() {
          window.chatMilvus.init({
            email: '${usuarioLogado?.email}',
            nome: '${usuarioLogado?.nome}',
            country: "+55",
            telefone: "",
            inicia_aberto: true
          });
        }, 600)`;
      this._renderer2.appendChild(this._document.body, _initWidget2);
    });

    document.addEventListener('closeButton', () => {
      let _script_widget = document.getElementById('script_widget');
      let _script_init = document.getElementById('init_widget');
      let _iframes = document.getElementsByTagName('iframe');
      let _body = document.getElementById('body-milvus');

      _script_widget.remove()
      _script_init.remove()
      this.getIndexIframeChat(_iframes, _body);
      if (_iframes[0]) _iframes[0].remove();
      if (_iframes[0]) _iframes[0].remove();
    });
  }

  setTheme(): void {
    if (window.localStorage.getItem('theme') !== null) {
      if (window.localStorage.getItem('theme') === 'dark') {
        this.themeService.setDarkTheme();
        this.loadTheme('material-dark')
      } else {
        this.themeService.setLightTheme();
        this.loadTheme('material')
      }
    } else {
      this.themeService.setLightTheme();
    }
  }

  verificaAuth(event): void {
    if (event.url === '/login' || event.url === '/trial/free-trial-ended' || event.url === '/remote-access' || event.url.includes('/outside-auth/confirm-auth')) {
      this.blockAllow(false);
    } else {
      setTimeout(() => {
        this.blockAllow(true);
        this.rotaIniciada = event.url.split(';')[0];
      }, 300);
    }
  }

  blockAllow(bool): void {
    if (bool) {
      document.querySelector('#d932kdds9fd9v').classList.add('main-content');
      this.isAuth = true;
    } else {
      setTimeout(() => {
        document.querySelector('#d932kdds9fd9v').classList.remove('main-content');
        this.isAuth = false;
      }, 300);
    }
  }

  loadTheme(theme: 'material' | 'material-dark') {
    const existingLink = document.getElementById('theme-css') as HTMLLinkElement;

    if (existingLink) {
      existingLink.href = `assets/styles/${theme}.css`;
    } else {
      const link = document.createElement('link');
      link.id = 'theme-css';
      link.rel = 'stylesheet';
      link.href = `assets/styles/${theme}.css`;
      document.head.appendChild(link);
    }
  }

  recuoPaginasAlerta(): void {
    document.querySelector('#d932kdds9fd9v').classList.add('main-content-with-alerts');
  }

  addNPSScript(): void {
    try {
      const usuarioLogado = this.tokenService.getUsuario();
      if (!usuarioLogado) return;

      const habilitarNps = usuarioLogado?.empresa?.is_trial || usuarioLogado?.empresa?.total_meses_ativos >= 70;
      if (!habilitarNps) return;

      console.log('Adicionando script NPS...');

      const _scriptNps = this._renderer2.createElement('script');
      _scriptNps.id = 'script_nps';

      // Define o conteúdo JavaScript da tag
      _scriptNps.text = `
        (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:5335534,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;

      this._renderer2.appendChild(this._document.body, _scriptNps);

      let _initNps = this._renderer2.createElement('script');
      _initNps.type = 'application/javascript';
      _initNps.id = 'init_nps';
      _initNps.text = `
        window.hj = window.hj || function(){(hj.q = hj.q || []).push(arguments)};
        var userId = ${usuarioLogado.id};
        var userEmail = '${usuarioLogado.username}';
        hj('identify', userId, {
        user_id: userId,
        email: userEmail,
        first_name: '${usuarioLogado.nome}',
        company: '#${usuarioLogado.empresa_id}'
        });
    `;

      this._renderer2.appendChild(this._document.body, _initNps);
    } catch (error) {
      console.error('Erro ao carregar script NPS', error);
    }
  }
}
