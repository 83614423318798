<div class="modal-content modal-milvus">
    <ngx-spinner></ngx-spinner>
    <div class="modal-header-milvus align-items-center dark-back">
        <span class="modal-title" id="modal-title-default">{{'VISUALIZAR_ARQUIVO' | translate}}</span>
    </div>
    <div class="modal-body preview-modal" style="text-align: center;">
        <ng-container *ngIf="fileType === 'image'">
            <img [src]="fileUrl" alt="Image Preview" class="full-body-preview" />
          </ng-container>
        
          <ng-container *ngIf="fileType === 'audio'">
            <audio controls class="full-body-preview">
              <source [src]="fileUrl" type="audio/mpeg" />
              {{'NAVEGADOR_NAO_SUPORTA_AUDIO' | translate}}
            </audio>
          </ng-container>

          <ng-container *ngIf="fileType === 'video'">
            <video controls class="full-body-preview">
              <source [src]="fileUrl" type="video/mp4" />
              {{'NAVEGADOR_NAO_SUPORTA_VIDEO' | translate}}
            </video>
          </ng-container>
        
          <ng-container *ngIf="fileType === 'pdf'">
            <iframe [src]="sanitizedUrl" class="full-body-preview"></iframe>
          </ng-container>
    </div>
    <div class="modal-footer modal-footer-milvus-+">
        <button type="button" class="btn btn-darkblue badge-pill" (click)="abrirNovaGuia()">            
          <span [translate]="'ABRIR_GUIA_EXTERNA'"></span>
        </button>
        <button type="button" class="btn btn-light badge-pill" (click)="close()">            
            <span [translate]="'FECHAR'"></span>
        </button>
    </div>
</div>
