import { FiltroPadraoService } from './../../../services/filtro-padrao.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoriasService } from 'src/app/services/categorias.service';
import { ChamadoProgramadoService } from 'src/app/services/chamado-programado.service';
import { ClienteContratoService } from 'src/app/services/cliente-contrato.service';
import { ChamadosService } from 'src/app/services/chamados.service';
import { ClienteService } from 'src/app/services/cliente.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { EquipesService } from 'src/app/services/equipes.service';
import { MesaTrabalhoService } from 'src/app/services/mesa-trabalho.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { SenhasService } from 'src/app/services/senhas.service';
import { SetorPadraoService } from 'src/app/services/setor-padrao.service';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { MonitoramentoService } from "src/app/services/monitoramento.service";
import { PerfilDispositivoService } from "src/app/services/perfil-dispositivo.service";
import { SoftwareService } from "src/app/services/software.service";
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/services/device.service';
import { MonitoramentoLinkService } from "src/app/services/monitoramento-link.service";
import { ClienteContatoService } from 'src/app/services/cliente-contato.service';
import { AlertasService } from 'src/app/services/alertas.service';
import { TarefaService } from 'src/app/services/tarefa.service';
import { AntivirusService } from 'src/app/services/antivirus.service';
import { ChecklistService } from 'src/app/services/checklist.service';
import { DispositivoLocadoService } from 'src/app/services/dispositivo-locado.service';
import { ClienteEnderecoService } from "src/app/services/cliente-endereco.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCriarGrupoComponent } from '../../modais/modal-criar-grupo/modal-criar-grupo.component';
import { TemplatesService } from 'src/app/services/templates.service';
import { ConfiguracaoContratosService } from 'src/app/services/configuracao-contratos.service';
import { CobrancasService } from 'src/app/services/cobrancas.service';
import { BibliotecaService } from 'src/app/services/biblioteca.service';
import { BibliotecaScriptService } from 'src/app/services/biblioteca-script.service';
import { DashboardModoTvService } from 'src/app/services/dashboard-modo-tv.service';
import { GatilhoChamadoService } from "src/app/services/gatilho-chamado.service";
import { FiltroAvancadoService } from 'src/app/services/filtroavancado.service';
import { ConfiguracaoTemplateService } from 'src/app/services/configuracao-template.service';
import { ClassifacaoProgramasServicosService } from 'src/app/services/classifacao-programas-servicos.service';
import { WidgetService } from 'src/app/services/widget.service';
import { GerenciadorLicencaService } from 'src/app/services/gerenciador-licenca.service';
import { WhiteLabelService } from 'src/app/services/white-label.service';
import { IntegrationService } from 'src/app/services/integration.service';
import { ChatService } from 'src/app/services/chat.service';
import { MotivoPausaService } from "src/app/services/motivo-pausa.service";
import { RelatorioPersonalizadoService } from 'src/app/services/relatorio-personalizado.service';
import { ListagemAlertasService } from 'src/app/services/listagem-alertas.service';
import { ConfiguracaoAvaliacaoClienteService } from 'src/app/services/configuracao-avaliacao-cliente.service';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { FormularioTicketService } from 'src/app/services/formulario-ticket.service';
import { CamposCustomizaveisService } from 'src/app/services/campos-customizaveis.service';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { CategoryGroupsService } from 'src/app/services/category-groups.service';
import { WhatsappService } from 'src/app/services/whatsapp.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpedientesService } from 'src/app/services/expedientes.service';
import { MaskApplierService } from 'ngx-mask';
import { ModalContatosComponent } from '../../modais/modal-contatos/modal-contatos.component';
import { AcessoRemotoService } from 'src/app/services/acesso-remoto.service';


@Component({
  selector: 'select-padrao',
  templateUrl: './select-padrao.component.html',
  styleUrls: ['./select-padrao.component.scss'],
})
export class SelectPadraoComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('selectPadrao', { static: false }) select;
  ctrl: Object = {
    selectedValue: {},
    itens: []
  };

  @Input() set valores(dados) {
    // Dados deve obedecer a interface de itens: { id: any, label: any }[]
    this.ctrl['itens'] = dados;
  }

  selectedLabel!: string;

  previousScrollTop = 0;
  @Input() listaValores = [];

  // Novos parâmetros para chamar api por demanda
  canLoadResources: boolean = false;
  @Input() fromEditTicket;

  @Input() selectId: string;
  @Input() entidade: string;
  @Input() entidadeRelatorioPersonalizado;
  parametros;
  @Input() set params(value: any) {
    if (value) {
      if(this.entidade === 'selectValorFiltroRelatorioPersonalizadoMultiSelectCriarTicket') {
        this.getDropdownValorRelatorioPersonalizadoMultiSelectCriarTicket(value)
      } else if(this.entidade === 'selectValorFiltroRelatorioPersonalizadoMultiSelect' ||
        this.entidade === 'selectValorFiltroRelatorioPersonalizado') {
        this.parametros = value.lista ? value.lista : [];
      } else {
        if (value) {
          this.parametros = value.id ? value.id : value;
        } else {
          this.parametros = null;
        }
      }
      this.getDataFromEntidade();
    }
  }6
  @Input() atributo: string;
  @Input() multiple: boolean;
  @Input() returnObj: boolean;
  @Input() set model(value) {
    if (this.entidade === 'selectBooleano' && value !== null && value !== undefined) {
      setTimeout(() => {
        this.ctrl['selectedValue'] = value;
      }, 1500);
    } else if (this.entidade === 'selectNomeCampo' && value.chave) {
      this.getChaveValor(value);
      setTimeout(() => {
        if (this.select !== undefined) {
          this.select.element.classList.remove('ng-invalid');
        }
      }, 500)
    } else if (this.entidade === 'selectStatusWorkflow') {
      setTimeout(() => {
        this.ctrl['itens'] = [
          {
            id: 1,
            text: this.translate.instant('RASCUNHO')
          },
          {
            id: 2,
            text: this.translate.instant('ATIVO')
          }
        ];
        if (value) {
          this.ctrl['selectedValue'] = value;
          this.select.element.classList.remove('ng-invalid');
        }
      }, 500)
    } else if (value !== null && value !== undefined && !Array.isArray(value)) {
      if (value.id !== undefined && value.id !== null) {
        setTimeout(() => {
          if (this.select !== undefined || value.id === 0) {
            this.select.element.classList.remove('ng-invalid');
          }
          if (!value.text && !this.fromEditTicket) {
            this.getFullData(value.id)
          } else {
            this.setaModel(value);
          }
        }, 500)
      } else if (value.id === null || value.id === undefined && this.entidade !== 'selectBooleano') {
        this.ctrl['selectedValue'] = {};
        if (this.params) {
          this.getDataFromEntidade();
        }
        if (this.select !== undefined) {
          setTimeout(() => {
            this.select.element.classList.add('ng-invalid');
          }, 200);
        }
      } else {
        this.setaModel(value);
        setTimeout(() => {
          if (this.select !== undefined || value.id === 0) {
            this.select.element.classList.remove('ng-invalid');
          }
        }, 500)
      }
    } else if (Array.isArray(value)) {
      this.setaModelMulti(value)

      setTimeout(() => {
        if (this.select !== undefined) {
          this.select.element.classList.remove('ng-invalid');
        }
      }, 500)
    }  else {
      this.ctrl['selectedValue'] = undefined;
      this.getDataFromEntidade();
      if (this.select !== undefined) {
        setTimeout(() => {
          this.select.element.classList.add('ng-invalid');
        }, 200);
      }
    }
  }
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Output() selectChange = new EventEmitter();
  @Input() checklist;
  @Input() licenca_param;
  @Input() software_geral_param;
  @Input() adicionaAll;
  @Input() mostra_users_chat;
  @Input() customPlaceholder;
  customAddItem = {
    id: null,
    text: null
  };
  @Input() set customItem(value) {
    this.customAddItem = value;
    this.addItem = true
    if (this.ctrl['itens'].length > 0 && this.customAddItem && this.customAddItem.id) {
      if (!this.ctrl['itens'].find(item => item.id === this.customAddItem.id)) {
        this.ctrl['itens'].push(this.customAddItem)
      }
    }
  };
  @Input() clienteId;
  @Input() pesquisaSelect;
  @Input() currentTicket;
  @Input() openReference;
  @Input() isModalTicketLogs;
  @Input() set limparModel(event) {
    if (event) {
      this.ctrl['selectedValue'] = null;
      this.emitValue(null)
    }
  };
  @Output() possuiItens = new EventEmitter();
  @Output() retornoValores = new EventEmitter();

  cleared = false;
  modelReceived = false;
  pagina = 1;
  ver_mais = true;
  total_registros = 50
  index: number;
  addItem: boolean;
  loading: boolean = false;
  newSubs: Subscription;
  searchText$ = new Subject<string>();
  is_filtrado: boolean = false;
  finishedRequest: boolean = true;
  timeoutVerifyData: any;

  constructor(
    private clienteService: ClienteService,
    private chamadoService: ChamadosService,
    private equipesService: EquipesService,
    private setorPadraoService: SetorPadraoService,
    private categoriasService: CategoriasService,
    private chamadoProgramadoService: ChamadoProgramadoService,
    private mesaTrabalhoService: MesaTrabalhoService,
    private usuariosService: UsuariosService,
    private monitoramentoService: MonitoramentoService,
    private perfiLService: PerfilService,
    private senhasService: SenhasService,
    private perfilDispositivoService: PerfilDispositivoService,
    private softwareService: SoftwareService,
    private toastr: ToastrService,
    private selectConfig: NgSelectConfig,
    public translate: TranslateService,
    private clienteContratoService: ClienteContratoService,
    private empresasService: EmpresasService,
    private deviceService: DeviceService,
    private monitoramentoLinkService: MonitoramentoLinkService,
    private clienteContatoService: ClienteContatoService,
    private alertaService: AlertasService,
    private antivirusService: AntivirusService,
    private tarefaService: TarefaService,
    private checklistService: ChecklistService,
    private dispositivoLocadoService: DispositivoLocadoService,
    private modalService: NgbModal,
    private templatesService: TemplatesService,
    private configuracaoContratoService: ConfiguracaoContratosService,
    private cobrancasService: CobrancasService,
    private serviceBiblioteca: BibliotecaService,
    private bibliotecaScriptService: BibliotecaScriptService,
    private modoTvDashboardService: DashboardModoTvService,
    private gatilhoChamadoService: GatilhoChamadoService,
    private filtroAvancadoService: FiltroAvancadoService,
    private chamadosService: ChamadosService,
    private configTemplateService: ConfiguracaoTemplateService,
    private classificacaoProgramasServicosService: ClassifacaoProgramasServicosService,
    private widgetService: WidgetService,
    private whiteLabelService: WhiteLabelService,
    private integracaoService: IntegrationService,
    private gerenciadorLicencaService: GerenciadorLicencaService,
    private chatService: ChatService,
    private motivoPausaService: MotivoPausaService,
    private relatorioPersonalizadoService: RelatorioPersonalizadoService,
    private listagemAlertasService: ListagemAlertasService,
    private configuracaoAvaliacaoCliente: ConfiguracaoAvaliacaoClienteService,
    private route: Router,
    private formularioService: FormularioTicketService,
    private zone: NgZone,
    private cd: ChangeDetectorRef,
    private filtrosService: FiltroPadraoService,
    private camposCustomizaveisService: CamposCustomizaveisService,
    private clienteEnderecoService: ClienteEnderecoService,
    private categoryGroupsService: CategoryGroupsService,
    private whatsappService: WhatsappService,
    private spinner: NgxSpinnerService,
    private expedientesService: ExpedientesService,
    private mask: MaskApplierService,
    private elem: ElementRef,
    private acessoRemotoService: AcessoRemotoService,
  ) {
    this.selectConfig.notFoundText = this.translate.instant('NENHUM_ITEM_ENCONTRADO');
    this.selectConfig.bindValue = 'value';
    this.searchText$.pipe(
      debounceTime(1000)
    ).subscribe(value => {
      this.filtrarPersonalizado(value);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      if(!this.parametros) {
        this.getDataFromEntidade();
      }
    }, 500);

    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.cd.detectChanges();
      }, 100);
    })
  }

  ngAfterViewInit(): void {
    this.cd.detach();
  }

  

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
    clearInterval(this.timeoutVerifyData);
  }

  async onSearchTermChange(value: any) {
    this.loading = true;
    await this.triggerSearch(value);
    this.loading = false;
  }

  triggerSearch(value: any): Promise<void> {
    return new Promise(resolve => {
      this.searchText$.next(value);
      setTimeout(() => {
        console.log('operação finalizada');
      resolve();
      }, 1000)
    });

  }

  getChaveValor(valor) {
    setTimeout(() => {
      var _aux = this.ctrl['itens'].find(x => x.chave === valor.chave);
      this.ctrl['selectedValue'] = _aux.id;
    }, 1500);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['atributo'] && changes['atributo'].currentValue && this.entidade === 'select') {
      this.atributo = changes['atributo'].currentValue
      this.getDropdownValorCamposLista();
    }
    if (changes['params'] && changes['params'].currentValue && this.entidade === 'selectDropdownValorCamposListaCondicao') {
      this.atributo = changes['params'].currentValue
      this.getDropdownValorCamposListaCondicao();
    }
    // if (changes['params'] && changes['params'].currentValue && this.entidade === 'selectValorFiltroRelatorioPersonalizadoMultiSelectCriarTicket') {
    //   this.atributo = changes['params'].currentValue
    //   this.getDropdownValorCamposListaCondicao();
    // }
  }

  hasOwnProperty(item: any, campo: any) {
    return item.hasOwnProperty(campo)
  }

  getFullData(id) {
    this.timeoutVerifyData = setInterval(() => {
      var _aux = (this.entidade === 'selectDistribuicaoAutomatica')
        ? this.ctrl['itens'].find(x => x.id === +id)
        : this.ctrl['itens'].find(x => x.id === id);
      
      if (_aux) {
        this.setaModel(_aux);
      }

      if (this.finishedRequest) {
        clearInterval(this.timeoutVerifyData);
      }
    }, 500);
  }

  emitValue(value): void {
    if (value === null || value === undefined) {
      setTimeout(() => {
        this.select.element.classList.add('ng-invalid');
      }, 200);
    } else {
      setTimeout(() => {
        this.select.element.classList.remove('ng-invalid');
      }, 200);
    }

    if (this.returnObj === true && value && !this.multiple) {
      if (this.adicionaAll && this.adicionaAll === true) {
        var itemObj = this.ctrl['itens'].filter(item => {
          return item.id === value;
        });
        this.selectChange.emit(itemObj[0]);
      } else {
        // var valueToNumber;

        // if (!isNaN(value)) {
        //   valueToNumber = Number(value);
        // } else {
        //   valueToNumber = value;
        // }
        var itemObj;

        if (this.entidade !== 'selectDataDinamicaCondicaoFiltro') {
          itemObj = this.ctrl['itens'].filter(item => {
            return item.id === value;
          });
        } else {
          itemObj = this.ctrl['itens'].filter(item => {
            return item.chave === value;
          });
        }

        if(value) {
          this.selectChange.emit(itemObj[0]);
        }else {
          this.selectChange.emit(0);
        }
        
      }

    } else {
      this.selectChange.emit(value);
    }
  }

  setaModel(valor) {
    setTimeout(() => {
      this.modelReceived = true;
      if (valor !== undefined && valor !== null && this.ctrl['itens'].length <= 0) {
        if (this.fromEditTicket && !this.canLoadResources) {
          if (this.entidade === 'selectDispositivoVinculado' || this.entidade === 'selectDispositivoVinculadoB2c') {
            this.ctrl['itens'].push({
              id: valor.id,
              text: valor.hostname
            });
          } else {
            this.ctrl['itens'].push(valor);
          }
        }
        this.ctrl['selectedValue'] = valor.id;
     } else {
        let found = this.ctrl['itens'].find(item => {
          if ((item.id === this.ctrl['selectedValue']) || (item.id === (valor.id ? valor.id : valor))) {
            return item;
          }
        });
        if (!found) {
          this.ctrl['itens'].push(valor);
        }
        this.ctrl['selectedValue'] = (valor.id || valor.id === 0) ? valor.id : valor;
      }
    }, 1000);
  }

  setaModelMulti(valor): void {
    this.modelReceived = true;
    setTimeout(() => {
      if (valor !== undefined && valor !== null) {
        this.ctrl['selectedValue'] = [];
        valor.forEach(item => {
          let value;
          if (this.entidade === 'selectValoresCondicaoFiltro') {
            value = (item.id) ? item.id : item;
          } else {
            value = (item.id) ? Number(item.id) : Number(item);
          }

          this.ctrl['selectedValue'].push(value);
        });
      }
    }, 100);
  }

  resetPageFiltrarPersonalizado(event) {
    if (event.term.length > 0) {
      this.pagina = 1;
      this.ver_mais = false;
    } else {
      this.ver_mais = true;
    }
  }

  debouncedGetDropdownProdutoPecas = _.debounce(filtroPeca => this.getDropdownProdutosPecas(filtroPeca), 400);

  filtrarPersonalizado(event): void {
    this.is_filtrado = event.term ? true : false;
    
    if (this.entidade === 'selectTicketsConciliados') {
      this.getDropdownTicketsConciliados(event.term);
    } else if (this.entidade === 'selectClientes' || this.entidade === 'selectClientesPerfil' || this.entidade === 'selectClientesFormulario' || this.entidade === 'selectAllClients') {
      this.resetPageFiltrarPersonalizado(event);

      this.newSubs = this.clienteService.getDropDownCliente({ filtro_body: { is_ativo: true, filtro: event.term, ...this.parametros } }, { total_registros: 50 })
        .subscribe(result => {
          this.ctrl['itens'] = result.dropDown.lista;
          this.loading = false;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.loading = false;
        })
    } else if(this.entidade === 'selectTipoSolicitante') {
      this.resetPageFiltrarPersonalizado(event);

      this.newSubs = this.clienteContatoService.dropDownSolicitantes({ is_tivo: true, valor: event.term }, { total_registros: 10 })
        .subscribe({
          next: (result) => {
            this.ctrl['itens'] = result.lista;
            this.loading = false;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.loading = false;
        }
      });
    } else if (this.entidade === 'selectProdutosPecas') {
      this.resetPageFiltrarPersonalizado(event);
      this.debouncedGetDropdownProdutoPecas({ filtro: event.term });
    } else if (this.entidade === 'selectServicosIntegracao' || this.entidade === 'selectServicos' || this.entidade === 'selectSelecaoIntegracao') {
      this.resetPageFiltrarPersonalizado(event);
      this.getServicosIntegracao(event.term)
    } else if (this.entidade === 'selectChamadoConciliar') {
      this.resetPageFiltrarPersonalizado(event);
      this.newSubs = this.chamadosService.dropdownChamadosPorCliente(
        { total_registros: 50 }, { filtro_body: { "cliente_id": this.parametros, "pesquisa": event.term } }
      )
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown.lista;
            this.loading = false;
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error.error ? error.error : error, '', environment.toastrConfig('danger'));
            this.loading = false;
          }
        });
    }
  }


  scrollToEnd(items): void {
    if (this.entidade === 'selectClientes' || this.entidade === 'selectClientesPerfil' || this.entidade === 'selectClientesFormulario' || this.entidade === 'selectAllClients') {
      this.verMaisDropdownClientes(items);
    } else if (this.entidade === 'selectProdutosPecas') {
      this.verMaisDropdownPecas(items)
    } else if (this.entidade === 'selectServicosIntegracao' || this.entidade === 'selectServicos' || this.entidade === 'selectSelecaoIntegracao') {
      this.verMaisDropdownServicosIntegracao(items);
    } else if (this.entidade === 'selectTicketsConciliados') {
      this.verMaisChamadosConciliar(items);
    } else if(this.entidade === 'selectTipoSolicitante') {
      this.verMaisDropdownSolicitantes(items);
    }
  }

  scrollToEndModal(event: Event) {
    const element = event.target as HTMLElement;

    const currentScrollTop = element.scrollTop;

    const atBottom = element.scrollHeight - element.scrollTop >= element.clientHeight;

    if (currentScrollTop < this.previousScrollTop) {
      return
    }

    if (atBottom) {
      if (!this.loading) {
        // INSERIR AQUI AS FUNÇÕES DE SCROLL DO SELECT QUANDO DENTRO DE MODAL AQUI
        if (this.entidade === 'selectTicketsConciliados') {
          this.verMaisChamadosConciliar();
        }
      }
    }

    this.previousScrollTop = currentScrollTop;
  }

  verificaPaginateResults(results) {
    if (results.dropDown.meta.paginate.current_page !== results.dropDown.meta.paginate.last_page.toString()) {
      this.ver_mais = true;
    } else {
      this.ver_mais = false;
    }
  }

  verMaisDropdownServicosIntegracao(filtro): void {
    if (this.ver_mais === true) {
      this.pagina = this.pagina + 1;
      this.loading = true;
      var _params = {
        filtro: this.pesquisaSelect ? this.pesquisaSelect : '',
        quantidade: 50,
        pagina: this.pesquisaSelect ? 1 : this.pagina
      };
      this.newSubs = this.clienteContratoService.getDropdownServicosIntegracao(_params)
        .subscribe(results => {
          results.lista.map((item => {
            this.ctrl['itens'].push(item);
          }))
          if (results.meta.paginate.current_page !== results.meta.paginate.last_page.toString()) {
            this.ver_mais = true;
          } else {
            this.ver_mais = false;
          }
          setTimeout(() => {
            this.loading = false;
          }, 100);
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  verMaisDropdownClientes(filtro): void {
    if (this.ver_mais === true) {
      this.loading = true;
      this.pagina++;
      this.newSubs = this.clienteService.getDropDownCliente({ filtro_body: { is_ativo: true } }, { total_registros: 50, pagina: this.pagina })
        .subscribe(results => {
          results.dropDown.lista.forEach(item => {
            filtro.push(item);
          });
          if (results.dropDown.meta.paginate.current_page !== results.dropDown.meta.paginate.last_page.toString()) {
            this.ver_mais = true;
          } else {
            this.ver_mais = false;
          }

          setTimeout(() => {
            this.loading = false;
          }, 100);
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  verMaisDropdownSolicitantes(filtro): void {
    if (this.ver_mais === true) {
      this.loading = true;
      this.pagina++;
      this.newSubs = this.clienteContatoService.dropDownSolicitantes({ is_ativo: true }, { total_registros: 10, pagina: this.pagina })
        .subscribe({
          next: (results) => {
          results.lista.forEach(item => {
            filtro.push(item);
          });

          if (results.meta.paginate.current_page !== results.meta.paginate.last_page.toString()) {
            this.ver_mais = true;
          } else {
            this.ver_mais = false;
          }

          setTimeout(() => {
            this.loading = false;
          }, 100);
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
        }
      });
    }
  }

  verMaisDropdownPecas(filtro): void {
    if (this.ver_mais === true) {
      this.loading = true;
      this.pagina++;
      this.newSubs = this.chamadoService.getDropdownProdutosPecas({ total_registros: 50, pagina: this.pagina })
        .subscribe(results => {
          results.forEach(item => {
            filtro.push(item);
          });
          this.loading = false
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
    }
  }

  verMaisChamadosConciliar(filtro?): void {
    if (this.ver_mais === true) {
      this.loading = true;
      this.pagina++;
      this.newSubs = this.chamadosService.getDropdownChamadosConciliados( this.parametros.cliente_id, this.parametros.chamado_id, undefined, this.pagina)
        .subscribe({
          next: (results) => {
            results.dropDown.forEach(item => {
              this.ctrl['itens'].push(item);
            });
            if (results.paginado.meta.paginate.current_page !== results.paginado.meta.paginate.last_page.toString()) {
              this.ver_mais = true;
            } else {
              this.ver_mais = false;
            }
            setTimeout(() => {
              this.loading = false;
            }, 100);
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error.error ? error.error : error, '', environment.toastrConfig('danger'));
          }
        });
      // this.newSubs = this.chamadosService.dropdownChamadosPorCliente({ total_registros: 50, pagina: this.pagina }, { filtro_body: { "cliente_id": this.parametros } })
      //   .subscribe({
      //     next: (results) => {
      //       results.dropDown.lista.forEach(item => {
      //         filtro.push(item);
      //       });
      //       if (results.dropDown.meta.paginate.current_page !== results.dropDown.meta.paginate.last_page.toString()) {
      //         this.ver_mais = true;
      //       } else {
      //         this.ver_mais = false;
      //       }

      //       setTimeout(() => {
      //         this.loading = false;
      //       }, 100);
      //     }, error: (error) => {
      //       console.error(error);
      //       this.toastr.show(error.error ? error.error : error, '', environment.toastrConfig('danger'));
      //     }
      //   });
    }
  }

  adicionarNovo(entidade): void {
    switch (entidade) {
      case 'grupo':
        const modalRef = this.modalService.open(ModalCriarGrupoComponent,
          {
            scrollable: false,
            windowClass: 'modal-custom-milvus',
            size: 'pequeno',
            keyboard: false,
            backdrop: 'static'
          });

        modalRef.result.then((result) => {
          this.getDropdownGrupos();
        }, (reason) => {
        });
        break;
    }
  }


  getDropdownFiltroAvancado() {
    this.newSubs = this.filtroAvancadoService.dropDownCampos().subscribe(
      (result) => {
        var cont = result.dropDown[result.dropDown.length - 1].id === '' ? 1 : result.dropDown[result.dropDown.length - 1].id + 1;
        this.ctrl['itens'] = result.dropDown.map((e) => {
          return {
            atributo: e.atributo,
            chave: e.chave,
            id: e.id === '' ? cont++ : e.id,
            is_personalizado: e.is_personalizado,
            text: e.label,
            tipo: e.tipo,
          }
        })
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      }
    )
  }

  getClientes(): void {
    this.newSubs = this.clienteService.getDropDownCliente({ filtro_body: { is_ativo: true, ...this.parametros } }, { total_registros: this.total_registros })
      .subscribe(result => {
        this.ctrl['itens'] = result.dropDown.lista;

        if (this.customAddItem && this.customAddItem.id && this.customAddItem.id !== null) {
          if (this.ctrl['itens'].length > 0) {
            var index = this.ctrl['itens'].findIndex(element => {
              if (element !== undefined && element.id) {
                element.id === this.customAddItem
              }
            });

            if (index === -1) {
              this.ctrl['itens'].unshift(this.customAddItem);
            } else {
              this.ctrl['itens'] = result.dropDown.lista;
              this.ctrl['itens'].filter(function (element) {
                if (element !== undefined && element.id) {
                  return element.id !== this.customAddItem.id;
                }
              });
            }
          }
        }

        this.total_registros = 50;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTipoSolicitante(): void {
    this.newSubs = this.clienteContatoService.dropDownSolicitantes({ is_ativo: true, ...this.parametros }, { total_registros: this.total_registros })
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.lista;

          this.total_registros = results.meta.paginate.to;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error)
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      })
  }

  getClientesPerfil(): void {
    this.newSubs = this.clienteService.getDropDownCliente('', { total_registros: 250 })
      .subscribe(result => {
        this.ctrl['itens'] = result.dropDown.lista;
        this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      })
  }

  getEquipes(): void {
    var _filtros = {};
    this.newSubs = this.equipesService.getListagemEquipes({}, _filtros)
      .subscribe(result => {
        this.ctrl['itens'] = result.lista;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      })
  }

  getSetores(): void {
    var _filtros;
    if (this.parametros) {
      _filtros = {
        cliente_id: this.parametros,
        is_ativo: "true"
      };
    } else {
      _filtros = {
        cliente_id: "__all__",
        is_ativo: "true"
      };
    }
    this.newSubs = this.setorPadraoService.dropDownSetores(_filtros)
      .subscribe(result => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      })
  }

  getSetoresB2c(): void {
    if (this.parametros.solicitante_id && this.parametros.tipo_solicitante_id) {
      this.newSubs = this.setorPadraoService.dropDownSetoresB2c({is_ativo: true, ...this.parametros})
        .subscribe({
          next: (result) => {
            this.ctrl['itens'] = result.dropDown;
            this.finishedRequest = true;
        }, error: (error) => {
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          console.error(error);
          this.finishedRequest = true;
        }
      });
    }
  }

  getCategoriaPrimaria(): void {
    var _params = {
      cliente_id: (this.parametros) ? this.parametros : '__all__',
      is_ativo: true
    };
    this.newSubs = this.categoriasService.getDropDownCategoriaPrimaria(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        if (this.adicionaAll && this.adicionaAll === true) {
          this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
        }
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      });
  }

  getCategoriaPrimariaAberturaTicket(): void {
    if (this.parametros) {
      var _params = {
        cliente_id: (this.parametros) ? this.parametros : '__all__',
        is_ativo: true
      };
      this.newSubs = this.categoriasService.getDropDownCategoriaPrimaria(_params)
        .subscribe(results => {
          this.ctrl['itens'] = results.dropDown;
          if (this.adicionaAll && this.adicionaAll === true) {
            this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
          }
          this.finishedRequest = true;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          console.error(error);
          this.finishedRequest = true;
        });
    }
  }

  getUnidadeNegocio() {
    var _params;
    if (this.parametros) {
      _params = {
        cliente_id: this.parametros,
        is_ativo: true
      };
    }

    this.newSubs = this.clienteEnderecoService.getClienteEnderecoById(_params)
      .subscribe(results => {
        this.ctrl['itens'] = this.setDropdownUnidadeDeNegocios(results.dropdown);
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  setDropdownUnidadeDeNegocios(unidadeDeNegocios) {
    unidadeDeNegocios.forEach((element) => {
      let logradouro = element.logradouro ? element.logradouro : "";
      let numero = element.numero ? ", " + element.numero : "";
      let complemento = element.complemento
        ? " - " + element.complemento
        : "";
      let cep = element.cep ? element.cep : "";
      let bairro = element.bairro ? ", " + element.bairro : "";
      let cidade = element.cidade ? " - " + element.cidade : "";
      let estado = element.estado ? " - " + element.estado : "";
      element.text = logradouro + numero + complemento;
      element.options = {
        address: {
          text: cep + bairro + cidade + estado,
          class: "font-size: 10px;",
        },
      };
    });

    return unidadeDeNegocios;
  }

  getCategoriaSecundaria(): void {
    var _params;

    if (this.parametros !== "__all__") {
      _params = {
        categoria_primaria_id: this.parametros,
        is_ativo: true,
        cliente_id: this.clienteId
      };
    } else {
      _params = {
        categoria_primaria_id: '__all__',
        is_ativo: true,
        cliente_id: this.clienteId
      }
    }

    this.newSubs = this.categoriasService.getDropDownCategoriaSecundaria(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        if (this.adicionaAll && this.adicionaAll === true) {
          if (results.dropDown.length > 0) {
            this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
          } else {
            this.ctrl['itens'] = [{ id: '__all__', text: this.translate.instant("TODOS") }];
          }
        }
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      })
  }

  getCategoriaSecundariaAberturaTicket() {
    if (this.parametros && this.clienteId) {
      let _params = {
        categoria_primaria_id: (this.parametros !== "__all__") ? this.parametros : "__all__",
        is_ativo: true,
        cliente_id: this.clienteId
      };

      this.newSubs = this.categoriasService.getDropDownCategoriaSecundaria(_params)
        .subscribe(results => {
          this.ctrl['itens'] = results.dropDown;
          if (this.adicionaAll && this.adicionaAll === true) {
            if (results.dropDown.length > 0) {
              this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
            } else {
              this.ctrl['itens'] = [{ id: '__all__', text: this.translate.instant("TODOS") }];
            }
          }
          this.finishedRequest = true;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        })
    } else {
      this.ctrl['itens'] = [];
      this.finishedRequest = true;
    }
  }

  getPeriodoChamado(): void {
    this.newSubs = this.chamadoProgramadoService.getPeriodoProgramado()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getRepeticaoChamado(): void {
    this.newSubs = this.chamadoProgramadoService.getRepeticaoProgramado()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getRepeticaoChamadoV2(retira_primeiro?): void {
    this.newSubs = this.chamadoProgramadoService.getRepeticaoProgramadoV2()
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropDown;
          if(retira_primeiro) {
            this.ctrl['itens'].shift();
            this.ctrl['itens'].pop();
          }
          this.finishedRequest = true;
        }, error: (error) => {
          this.toastr.show(error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          console.error(error)
          this.finishedRequest = true;
        }
      });
  }

  getDiaChamado(): void {
    this.newSubs = this.chamadoProgramadoService.getDiaProgramado(this.parametros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        if(this.ctrl['itens'].length) {
          this.ctrl['itens'].forEach(item => {
            if(typeof(item.text) === 'string') {
              item.abb = item.text.substring(0, 3);
            }
          });
        }

        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getFrequenciaChamado(): void {
    this.newSubs = this.chamadoProgramadoService.getFrequenciaProgramado()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getFrequenciaChamadoV2(): void {
    this.newSubs = this.chamadoProgramadoService.getFrequenciaProgramadoV2()
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error: (error) => {
          this.toastr.show(error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          console.error(error)
          this.finishedRequest = true;
        }
      });
  }

  getTipoChamado(): void {
    if (this.parametros) {
      this.newSubs = this.chamadoProgramadoService.getTipoTicket(this.parametros)
        .subscribe(results => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          console.error(error)
          this.finishedRequest = true;
        })
    } else {
      this.newSubs = this.chamadoProgramadoService.getTipoTicket()
        .subscribe(results => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          console.error(error)
          this.finishedRequest = true;
        })
    }
  }

  getDropDownMesasUpdateAndCreateTicket(): void {
    this.ctrl['itens'] = [];
    
    var _params = {};
    if (!this.parametros) {
      _params = {
        is_ativo: "__all__",
        is_excluido: "false",
        filtrar_mesas_tecnico: "__all__"
      };
    } else {
      _params = this.parametros;
    }
    this.newSubs = this.mesaTrabalhoService.getDropDownMesasUpdateTicket(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getMesaTrabalho(): void {
    var _params = {};
    if (!this.parametros) {
      _params = {
        is_ativo: "__all__",
        is_excluido: "false",
        filtrar_mesas_tecnico: true
      };
    } else {
      _params = this.parametros;
    }
    this.newSubs = this.mesaTrabalhoService.getDropDownMesas(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getTecnicos(): void {
    this.ctrl['itens'] = []

    let _params;

    if (this.parametros) {
      _params = {
        is_ativo: "true",
        mesa_trabalho_id: this.parametros,
        mostra_users_chat: this.mostra_users_chat ? this.mostra_users_chat.toString() : "false"
      };
    } else {
      _params = {
        is_ativo: "true",
        mostra_users_chat: this.mostra_users_chat ? this.mostra_users_chat.toString() : "false"
      };
    }
    this.newSubs = this.usuariosService.getDropDownUsuarios(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTimezone(): void {
    this.newSubs = this.clienteService.getDropdownTimezone()
      .subscribe(results => {
        results.dropdown.forEach(time => {
          this.ctrl['itens'].push({
            id: time, text: time
          });
        });
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownPeriodoContrato(): void {
    this.newSubs = this.clienteContratoService.getDropdownPeriodoContrato()
      .subscribe(results => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownMoedas(): void {
    this.newSubs = this.empresasService.getDropdownMoedas()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownDiaVencimentoContrato(): void {
    this.newSubs = this.clienteContratoService.getDropdownDiaVencimentoContrato()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownDiaFaturamentoContrato(): void {
    this.newSubs = this.clienteContratoService.getDropdownDiaFaturamentoContrato()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getGatilhosIntegracao(): void {
    this.newSubs = this.clienteContratoService.getDropdownGatilhoIntegracao()
      .subscribe(results => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }


  getServicosIntegracao(filtro?): void {
    var _params = {
      filtro: this.pesquisaSelect ? this.pesquisaSelect : filtro ? filtro : '',
      quantidade: 50,
      pagina: this.pagina
    }
    this.newSubs = this.clienteContratoService.getDropdownServicosIntegracao(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.lista;
        this.finishedRequest = true;
        this.loading = false;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
        this.loading = false;
      })
  }

  getDropdownCatalogoServicos(): void {
    this.newSubs = this.clienteContratoService.getDropdownCatalogoServicos()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTipoCondicoes(): void {
    this.newSubs = this.monitoramentoService.getDropDownTipoCondicoes()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getPerfis(): void {
    this.newSubs = this.perfiLService.getPerfis()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTipoAcoes(): void {
    this.newSubs = this.monitoramentoService.getDropDownTipoAcao()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getGatilhoAcao(): void {
    this.newSubs = this.monitoramentoService.getDropDownGatilhoAcao()
      .subscribe(results => {
        var res = results.dropDown.filter(function (item) {
          return item.id !== 0
        })
        this.ctrl['itens'] = res;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getScript(): void {
    this.newSubs = this.monitoramentoService.getDropDownScripts()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getAcaoExpiraSenha(): void {
    this.newSubs = this.senhasService.getAcoes()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTipoSistemaOperacional(): void {
    this.newSubs = this.perfilDispositivoService.getTipoSistemaOperacional()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getExpressoes(): void {
    this.newSubs = this.perfilDispositivoService.getExpressoes()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getOSVersoes(): void {
    if (this.parametros == undefined) return
    let id = this.parametros
    this.newSubs = this.perfilDispositivoService.getOSVersoes(id)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getSoftwares(): void {
    let _params = {
      filtro: ''
    };
    this.newSubs = this.softwareService.getSoftwares(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown.lista;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getAtualizarMassa(): void {
    this.newSubs = this.chamadoService.getAtualizacaoMassa()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getPrioridadeChamado(): void {
    this.newSubs = this.chamadoService.getPrioridades()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownOfTypes(): void {
    this.newSubs = this.deviceService.getDropdownOfTypes()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDispositivoVinculado() {
    var _filtros = {
      cliente_id: this.parametros,
      is_ativo: "1"
    };

    if (this.software_geral_param) {
      _filtros['software_geral_id'] = this.software_geral_param
    }

    this.newSubs = this.deviceService.getDropdownOfLinkeds(_filtros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDispositivoVinculadoB2c(): void {
    if (this.parametros.solicitante_id && this.parametros.tipo_solicitante_id) {
      this.newSubs = this.deviceService.getDropdownOfLinkedsB2c(this.parametros)
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown;
            this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
    }

  }

  getCondicaoEventoAlertas() {
    this.newSubs = this.alertaService.getAlertasByEmpresa().subscribe(
      results => {
        var aux = results.find(
          obj => obj.tipo_alerta_id == 16
        )

        var listaAux = aux.condicao_eventos.split(",");
        for (let i = 0; i < listaAux.length; i++) {
          var _objeto = { id: i, text: listaAux[i] }
          this.ctrl['itens'].push(_objeto);
        }
        this.finishedRequest = true;

      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getArteGrafica() {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('ASSINATURA') },
      { id: 2, text: this.translate.instant('LOGO') },
      { id: 3, text: this.translate.instant('PAPEL_PAREDE') },
    ];
  }

  getTipoMonitoramento() {
    this.newSubs = this.monitoramentoLinkService.getDropdownTipoMonitoramento()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getChamadosRelacionados() {
    if(localStorage.getItem('clienteId') !== null) {
      this.parametros = localStorage.getItem('clienteId');
    }
    localStorage.removeItem('clienteId');
    if (this.parametros) {
      this.newSubs = this.tarefaService.getDropdownChamadosTarefa(this.parametros)
        .subscribe(results => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        })
    } else {
      this.ctrl['itens'] = [];
    }
  }

  getGatilhoAcaoLink() {
    this.newSubs = this.monitoramentoLinkService.getDropdownGatilhoAcao()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTipoTarefa() {
    this.newSubs = this.tarefaService.getDropdownTipoTarefa(this.parametros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getPeriodoLink() {
    this.newSubs = this.monitoramentoLinkService.getDropdownPeriodo()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getConfiguracaoAlerta() {
    if(this.parametros.tipo_alerta_id === 1 ||
      this.parametros.tipo_alerta_id === 4 ||
      this.parametros.tipo_alerta_id === 6 ||
      this.parametros.tipo_alerta_id === 10 ||
      this.parametros.tipo_alerta_id === 16
   ) {
     this.ctrl['itens'] = [
       { id: 'IGNORAR', text: this.translate.instant('IGNORAR') },
       { id: 'CONFIGURACAO_GLOBAL', text: this.translate.instant('CONFIGURACAO_GLOBAL') },
     ];
     if(this.parametros.tela === 'ALERTA_CLIENTE') {
      this.ctrl['itens'].push({ id: 'CONFIGURACAO_CLIENTE', text: this.translate.instant('CONFIGURACAO_CLIENTE') })
     }
     if(this.parametros.tela === 'ALERTA_DISPOSITIVO') {
      this.ctrl['itens'].push({ id: 'CONFIGURACAO_DISPOSITIVO', text: this.translate.instant('CONFIGURACAO_DISPOSITIVO') })
     }
   } else {
     this.ctrl['itens'] = [
       { id: 'IGNORAR', text: this.translate.instant('IGNORAR') },
       { id: 'ATIVO', text: this.translate.instant('ATIVO') }
     ];
   }
  }

  getTipoContato() {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('USUARIO') },
      { id: 2, text: this.translate.instant('CONTATO') },
    ];
  }

  getClienteContato() {
    var _filtros = {
      filtro_body: {
        cliente_id: this.parametros
      }
    };

    this.newSubs = this.clienteContatoService.getDropDownClienteContato(_filtros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getClienteContatoB2c(): void {
    if (this.parametros.solicitante_id && this.parametros.tipo_solicitante_id) {
      this.newSubs = this.clienteContatoService.dropDownContatosB2c(this.parametros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
    }
  }

  getDropdownGrupos() {
    this.newSubs = this.checklistService.getDropdownOfGroups().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownObrigatorios() {
    this.ctrl['itens'] = [
      { id: true, text: this.translate.instant('SIM') },
      { id: false, text: this.translate.instant('NAO') },
      { id: 1, text: this.translate.instant('SIM'), value: true },
      { id: 2, text: this.translate.instant('NAO'), value: false },
    ];
  }

  getDropdownTemplates(): void {
    this.newSubs = this.templatesService.getDropdownTemplate(this.parametros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }
  getCategoriaBaseConhecimento() {
    this.newSubs = this.serviceBiblioteca.dropdownCategoriasBaseConhecimento().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownAberturaChamado() {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('BLOQUEIO') },
      { id: 2, text: this.translate.instant('ALERTA') },
    ];
  }

  getDropdownContatosCliente(): void {
    var _filtros = {
      filtro_body: {
        cliente_id: this.parametros
      }
    };

    this.newSubs = this.clienteContatoService.getDropdownContatosCliente(_filtros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownAnexosChamado(): void {
    this.newSubs = this.chamadoService.getDropdownAnexosChamado(this.parametros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownProdutosPecas(filtroPeca?): void {
    this.newSubs = this.chamadoService.getDropdownProdutosPecas(filtroPeca)
      .subscribe(results => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
        this.loading = false;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
        this.loading = false;
      })
  }

  getDropdownTicketsConciliados(filtros?): void {
    if (filtros) {
      this.newSubs = this.chamadoService.getDropdownChamadosConciliados(this.parametros.cliente_id, this.parametros.chamado_id, filtros)
        .subscribe(results => {
          this.ctrl['itens'] = this.currentTicket ? results.dropDown.filter((result: { codigo: number; }) => result.codigo !== this.currentTicket.codigo) : results.dropDown;
          this.finishedRequest = true;
          this.loading = false;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
          this.loading = false;
        })
    } else {
      this.newSubs = this.chamadoService.getDropdownChamadosConciliados(this.parametros.cliente_id, this.parametros.chamado_id)
        .subscribe(results => {
          this.ctrl['itens'] = this.currentTicket ? results.dropDown.filter((result: { codigo: number; }) => result.codigo !== this.currentTicket.codigo) : results.dropDown;
          this.finishedRequest = true;
          this.loading = false;
        }, error => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
          this.loading = false;
        })
    }
  }

  getUserTelaInicial() {
    this.usuariosService.getDropDownUsuarioTelaInicial().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getSimNao() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('SIM'), value: true },
      { id: 2, text: this.translate.instant('NAO'), value: false },
    ];
  }

  getBooleano() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: true, text: this.translate.instant('SIM'), value: true },
      { id: false, text: this.translate.instant('NAO'), value: false },
    ];
  }

  getBooleanoRegra() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: '1', text: this.translate.instant('SIM'), value: true },
      { id: '2', text: this.translate.instant('NAO'), value: false },
    ];
  }

  getAtivoInativo() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('ATIVO'), value: true },
      { id: 2, text: this.translate.instant('INATIVO'), value: false },
    ];
  }

  getValorTipoPessoa() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant("PESSOA_FISICA"), value: true },
      { id: 2, text: this.translate.instant("PESSOA_JURIDICA"), value: false }
    ];
  }

  getDropdownAlteracaoChamado() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('BLOQUEIO') },
      { id: 2, text: this.translate.instant('ALERTA') },
    ];
  }

  getDispositivosLocados() {
    this.newSubs = this.dispositivoLocadoService.getDispositivoTipos().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  dropdownCategoriaPrimariaConfiguracaoContrato() {
    this.newSubs = this.configuracaoContratoService.dropdownCategoriaPrimaria().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getClienteEndereco() {
    let _filtro = {
      cliente_id: this.parametros
    }
    this.newSubs = this.clienteEnderecoService.getClienteEnderecoById(_filtro).subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropdown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  dropdownCategoriaSecundariaConfiguracaoContrato() {
    var _filtros = {
      categoria_primaria_id: this.parametros,
      is_ativo: "__all__"
    }

    this.newSubs = this.configuracaoContratoService.dropdownCategoriaSecundaria(_filtros).subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  dropdownMoedas() {
    this.newSubs = this.cobrancasService.dropdownMoedas().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  dropDownListClienteFaturamento() {
    this.newSubs = this.cobrancasService.dropDownListClienteFaturamento(this.parametros).subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  dropdownGatilhos() {
    this.newSubs = this.cobrancasService.dropdownGatilhos().subscribe(
      (results) => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  dropdownServicos() {
    this.newSubs = this.cobrancasService.dropdownServicos().subscribe(
      (results) => {
        this.ctrl['itens'] = results.lista;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  obterModelosChatGpt() {
    this.integracaoService
      .getModelosChatGpt()
      .subscribe({
        next: (result) => {
          result.data.forEach(element => {
            this.ctrl['itens'].push({ id: element.id, text: element.id, value: element.id })
          });
        },
        error: (error) => {
          console.error(error);
          this.toastr.show(error, "", environment.toastrConfig("danger"));
        },
      });
  }

  getTokenizar() {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('SIM'), value: 1 },
      { id: 2, text: this.translate.instant('NAO'), value: 0 }
    ];
  }

  getTipoArtigoBaseConhecimento() {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('SOLUCAO_PERMANENTE') },
      { id: 2, text: this.translate.instant('SOLUCAO_ALTERNATIVA') },
    ];
  }

  getSituacaoBaseConhecimento() {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('RASCUNHO') },
      { id: 2, text: this.translate.instant('INTERNO') },
      { id: 3, text: this.translate.instant('CLIENTE_') },
      { id: 4, text: this.translate.instant('PARA_TODOS_FAQ') },
    ];
  }

  getPastasBibliotecaScript() {
    this.newSubs = this.bibliotecaScriptService.dropDownList().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getSistemaOperacionalBibliotecaScript() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: 'Windows' },
      { id: 2, text: 'Windows Server' },
      { id: 3, text: 'Ubuntu Desktop' },
      { id: 4, text: 'Android' },
    ];
  }

  getExtensaoBibliotecaScript() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: '.bat' },
      { id: 2, text: '.vbs' },
      { id: 3, text: '.sh' },
      { id: 4, text: 'Power Shell' },
    ];
  }

  getFiltroAvancado() {
    this.newSubs = this.modoTvDashboardService.dropDown().subscribe(
      (result) => {
        this.ctrl['itens'] = result;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getTempoFiltroAvancado() {
    this.newSubs = this.modoTvDashboardService.dropDownData({ is_data_futura: true }).subscribe(
      (result) => {
        this.ctrl['itens'] = result
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getListaDashboards() {
    this.newSubs = this.modoTvDashboardService.dropdownListDashboard().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropdown
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getGatilhoChamadoTipo() {
    this.newSubs = this.gatilhoChamadoService.getDropdownTipo().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getStatus() {
    this.newSubs = this.filtroAvancadoService.getStatus().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getGatilhoChamadoCondicao() {
    if (this.parametros) {
      this.getDropdownCondicoes(this.parametros);
    } else {
      this.getDropdownCondicoes();
    }
  }

  getDropdownCondicoes(params?) {
    this.newSubs = this.gatilhoChamadoService.getDropdownCondicoes(params).subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getDataFiltroAvancado() {
    this.newSubs = this.filtroAvancadoService.dropDownData({ is_data_futura: true }).subscribe(
      (result) => {
        this.ctrl['itens'] = result
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getGatilhoChamadoOperadores() {
    this.newSubs = this.gatilhoChamadoService.getDropdownOperadores().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getGatilhoChamadoValor() {
    this.finishedRequest = true;
    this.ctrl['itens'] = this.parametros;
  }

  getGatilhoChamadoOperador() {
    this.finishedRequest = true;
    this.ctrl['itens'] = this.parametros
  }

  getGatilhoChamadoTipoAcao() {
    this.newSubs = this.gatilhoChamadoService.getDropdownTipoAcao().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getTipoAtendimento() {
    this.newSubs = this.gatilhoChamadoService.getDropdownTipoAtendimento()
    .subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getGatilhoChamadoAcao() {
    this.newSubs = this.gatilhoChamadoService.getDropdownAcao(this.parametros).subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getGatilhoChamadoDestinatario() {
    this.newSubs = this.gatilhoChamadoService.getDropdownDestinatario().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getChecklist() {
    this.newSubs = this.checklistService.dropdown().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getSlaResposta() {
    this.newSubs = this.chamadosService.getStatusSla()
      .subscribe(
        (results) => {
          this.ctrl['itens'] = results.dropDown
          this.finishedRequest = true;
        }, (error) => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        })
  }

  getSlaSolucao() {
    this.newSubs = this.chamadosService.getStatusSla()
      .subscribe(
        (results) => {
          this.ctrl['itens'] = results.dropDown
          this.finishedRequest = true;
        }, (error) => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        })
  }

  getListaMacros() {
    this.newSubs = this.configTemplateService.dropdownMacros().subscribe(
      (results) => {
        this.ctrl['itens'] = results.dropDown
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getListaTiposDeTemplates() {
    this.newSubs = this.templatesService.dropdownTemplateTipoResposta().subscribe(
      result => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTipoClassificacao() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('SOFTWARE') },
      { id: 2, text: this.translate.instant('SERVICO') },
    ];
  }

  getConfiavelClassificacao() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 'true', text: this.translate.instant('SIM') },
      { id: 'false', text: this.translate.instant('NAO') },
    ];
  }

  getGatilhoClassificacao() {
    this.newSubs = this.classificacaoProgramasServicosService.dropDownGatilhoAcao().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getTipoDistribuicao() {
    this.newSubs = this.widgetService.getTipoDistribuicao().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getVersaoClassificacao() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('DEFINIR_VERSAO'), allVersao: false },
      { id: 2, text: this.translate.instant('TODAS_VERSOES'), allVersao: true },
    ];
  }

  getIconeWhiteLabelOnline() {
    this.newSubs = this.whiteLabelService.iconesOnline().subscribe(
      (result) => {
        this.ctrl['itens'] = result;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getIconeWhiteLabelOffline() {
    this.newSubs = this.whiteLabelService.iconesOffline().subscribe(
      (result) => {
        this.ctrl['itens'] = result;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getTipoSelecaoIntegracao() {
    this.newSubs = this.integracaoService.dropdownTipoServico().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getSelecaoIntegracao() {
    this.newSubs = this.integracaoService.dropdownServico().subscribe(
      (result) => {
        this.ctrl['itens'] = result.lista;
        this.finishedRequest = true;
      },
      (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownLicenca() {
    var licencas = []

    this.newSubs = this.gerenciadorLicencaService.dropdownLicencas(this.licenca_param).subscribe(
      (result) => {
        result.dropDown.forEach(element => {
          var _aux = {
            id: element.id,
            text: element.texto
          }
          licencas.push(_aux)
        });
        this.ctrl['itens'] = licencas;
        this.finishedRequest = true;
      },
      (error) => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getFonts() {
    this.finishedRequest = true;
    this.ctrl['itens'] = environment.getFontes().arrayFonts
  }

  getSelectPrioridade() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('CRITICO') },
      { id: 2, text: this.translate.instant('ALTO') },
      { id: 3, text: this.translate.instant('MEDIO') },
      { id: 4, text: this.translate.instant('BAIXO') },
      { id: 5, text: this.translate.instant('PLANEJADO') }
    ];
  }

  getDropdownPaises(): void {
    this.newSubs = this.clienteService.getDropdownPaises()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownEmailDominio() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('BLOQUEADO') },
      { id: 2, text: this.translate.instant('LIBERADO') },
    ];
  }

  getDropdownComentarioTemplate() {
    var lista = [];
    this.newSubs = this.templatesService.dropdownComentarios().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      }, (error) => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getAllClientes(): void {
    this.newSubs = this.clienteService.getDropDownCliente('', {})
      .subscribe(result => {
        this.ctrl['itens'] = result.dropDown.lista;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getMotivoPausa(): void {
    this.newSubs = this.motivoPausaService.dropdownMotivoPausa()
      .subscribe(result => {
        this.ctrl['itens'] = result.dropDown;
        if (this.parametros && this.parametros.all) {
          this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant('TODOS') });
        }
        if (result.dropDown.length > 0) {
          this.required = true
        }
        this.finishedRequest = true;

        this.possuiItens.emit(result.dropDown.length > 0);
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getClienteFormulario() {
    this.newSubs = this.clienteService.getDropDownCliente('', { total_registros: 250 })
      .subscribe(result => {
        this.ctrl['itens'] = result.dropDown.lista;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getDropdownGatilhoAlertaTipo(): void {
    this.newSubs = this.alertaService.getDropdownTiposAlertas()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownOperadoresAlerta(): void {
    this.newSubs = this.alertaService.getDropdownOperadoresAlertas()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownCondicaoAlertas(): void {
    this.newSubs = this.alertaService.getDropdownCondicoesAlertas()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownAlertaTipo(): void {
    this.newSubs = this.listagemAlertasService.getDropDownListAlertas()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown.filter((resutado: any) => resutado.id !== 20);
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDropdownGatilhoAlerta(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = this.parametros;
  }

  getDropdownMesesAvaliacao(): void {
    this.newSubs = this.configuracaoAvaliacaoCliente.getDropdownMesesAvaliacao().subscribe(
      result => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownChamadosConciliadosSimplified(): void {
    this.newSubs = this.chamadoService.getDropdownChamadosConciliadosSimplified(this.parametros).subscribe(
      result => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownProvedor() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('OUTROS') },
      { id: 2, text: 'Google' },
      { id: 3, text: 'Office365' },
    ];
  }

  getDropdownPlanos() {
    this.newSubs = this.empresasService.getPlanoDropdown().subscribe(
      result => {
        this.ctrl['itens'] = result.lista;
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getVisibilidadeFiltros(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('PRIVADO') },
      { id: 2, text: this.translate.instant('COMPARTILHADO') }
    ];
  }

  getDropdownEntidadeFiltros(): void {
    this.newSubs = this.filtrosService.getDropdownEntidades().subscribe(
      (results) => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    );
  }

  getDropdownCamposFiltros(): void {
    this.newSubs = this.filtrosService.getDropdownCampos().subscribe(
      (results) => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    );
  }

  getDropdownOperadoresFiltros(): void {
    let params = {};

    if (this.parametros) {
      params = {
        tipo_campo_id: this.parametros
      };
    }

    this.newSubs = this.filtrosService.getDropdownOperadores(params).subscribe(
      (results) => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    );
  }

  getDropdownValoresCondicaoFiltros(): void {
    this.newSubs = this.filtrosService.getDropdownValores(this.parametros).subscribe(
      (results) => {
        this.ctrl['itens'] = results;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownDataDinamicaCondicaoFiltro(): void {
    this.newSubs = this.filtrosService.getDropdownValores(this.parametros)
      .subscribe(
        (results) => {
          this.ctrl['itens'] = results;
          this.finishedRequest = true;
        }, (error) => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      )
  }

  getBusinessUnitCustom(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = this.parametros;
  }

  getBusinessUnitCustomB2c(): void {
    if (this.parametros.solicitante_id && this.parametros.tipo_solicitante_id) {
      this.newSubs = this.chamadosService.dropDownUnidadeNegocioB2c(this.parametros)
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropdown;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      })
    }
  }

  getCategoriesGroups(): void {
    this.newSubs = this.categoryGroupsService.dropdownGroups()
      .subscribe({
        next: (results) => {
          if (this.parametros) {
            this.ctrl['itens'] = results.filter(group => {
              return group.is_ativo === true
            });
          } else {
            this.ctrl['itens'] = results;
          }
          this.finishedRequest = true;
        }, error: (error) => {
          this.toastr.show(error.error_msg, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
  }

  getWhatsappIntegrationType(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 2, text: 'Milvus' },
      { id: 4, text: 'Meta' }
    ];
  }

  getWppOfficialTemplates(): void {
    this.newSubs = this.whatsappService.wppOfficialGetTemplates(this.parametros)
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = [];
          results.forEach(item => {
            this.ctrl['itens'].push({
              id: results.indexOf(item) + 1,
              template_id: item.id_template,
              text: item.name,
              lang: item.language,
              content: item.bodyText
            });
            if (this.ctrl['itens'].length === 1) {
              this.ctrl['selectedValue'] = this.ctrl['itens'][0];
            }
          });
          this.retornoValores.emit(results)
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
  }

  getExpedientes(): void {
    this.newSubs = this.expedientesService.dropDownExpedientes()
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      })
  }

  getSeveridadeAlertaAcronis(): void {
    this.newSubs = this.integracaoService.dropDownSeveridadesAcronis()
      .subscribe({
        next: (results) => {
          if (results) {
            this.ctrl['itens'] = [];
            for (const severidade in results.severidadeDropdown) {
              this.ctrl['itens'].push({
                id: severidade,
                text: this.traduzSeveridadeAcronis(severidade)
              });
            }
          }
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
  }

  traduzSeveridadeAcronis(severidade): string {
    switch (severidade) {
      case "critical":
        return this.translate.instant('CRITICO');
      case "information":
        return this.translate.instant('INFORMATIVO');
      case "warning":
        return this.translate.instant('AVISO');
      case "error":
        return this.translate.instant('ERRO');
    };
  }

  getCategoriasAcronis(): void {
    this.newSubs = this.integracaoService.dropDownCategoriasAcronis()
      .subscribe({
        next: (results) => {
          if (results && results.items) {
            this.ctrl['itens'] = [];
            results.items.forEach(categoria => {
              if (!categoria.disabled) {
                this.ctrl['itens'].push({
                  id: categoria.name,
                  text: categoria.name
                });
              }
            });
          }
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
  }

  getAcoesAlertasAcronis(): void {
    this.newSubs = this.integracaoService.dropDownAcoesAcronis()
      .subscribe({
        next: (results) => {
          if (results) {
            this.ctrl['itens'] = [];
            for (const acao in results.acoesDropdown) {
              this.ctrl['itens'].push({
                id: acao,
                text: this.traduzAcoesAcronis(acao)
              });
            }
          }
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
  }

  traduzAcoesAcronis(acao): string {
    switch (acao) {
      case "criar_ticket":
        return this.translate.instant('CRIAR_TICKET');
      case "ignorar":
        return this.translate.instant('IGNORAR');
      case "criar_alerta":
        return this.translate.instant('CRIAR_ALERTA');
    }
  }

  getWorkflowsChat(): void {
    this.newSubs = this.chatService.dropDownWorkflowChat()
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error ? error.error : error, '', environment.toastrConfig('danger'));
        }
      });
  }

  getWorkflowsWhatsapp(): void {
    if(this.parametros) {
      this.newSubs = this.chatService.dropdownWorkflowsWhatsapp(this.parametros)
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown;
            this.finishedRequest = true;
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error.error ? error.error : error, '', environment.toastrConfig('danger'));
          }
        });
    } else {
      this.finishedRequest = true;
    }
  }

  getChamadosConciliar(): void {
    this.newSubs = this.chamadosService.dropdownChamadosPorCliente({ total_registros: 50, pagina: this.pagina }, { filtro_body: { cliente_id: this.parametros } })
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropDown.lista;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error ? error.error : error, '', environment.toastrConfig('danger'));
        }
      });
  }
  
  getDropdownWorkflowstatus() {
    
  }

  getDropdownNumerosWorkflow(): void {
    this.newSubs = this.chatService.dropdownNumerosWorkflow({ provider_id: [4, 2] })
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = [];
          if (results.dropDown.length > 0) {
            results.dropDown.forEach((element) => {
              element.text = this.mask.applyMask(element.text, element.text.length === 12 ? '+00 (00) 0000-0000' : '+00 (00) 00000-0000');
              this.ctrl['itens'].push(element);
            });
          }

          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error ? error.error : error, '', environment.toastrConfig('danger'));
        }
      });
  }

  getDropdownAppsContato(): void {
    this.newSubs = this.clienteContatoService.dropDownAppsContatos()
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error ? error.error : error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      });
  }

  getTipoContatoSolicitante(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      {
        id: 1,
        text: 'Contato com cliente associado (B2B)'
      },
      {
        id: 2,
        text: 'Contato sem cliente associado (B2C)'
      }
    ];
  }

  getDropdownQuandoV2(): void {
    if(this.parametros) {
      this.newSubs = this.gatilhoChamadoService.dropdownQuandoV2(this.parametros)
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown;
            this.finishedRequest = true;
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error && error.error ? error.error : error, '', environment.toastrConfig('danger'));
            this.finishedRequest = true;
          }
        });
    }
  }

  getDropdownTipoAprovacao(): void {
    if(this.parametros) {
      this.newSubs = this.gatilhoChamadoService.dropdownTipoAprovacao(this.parametros)
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown;
            this.finishedRequest = true;
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error && error.error ? error.error : error, '', environment.toastrConfig('danger'));
            this.finishedRequest = true;
          }
        });
    }
  }

  getDropdownTipoAprovadoresV2(): void {
    if(this.parametros) {
      this.newSubs = this.gatilhoChamadoService.dropdownTipoAprovadorV2(this.parametros)
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown;
            this.finishedRequest = true;
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error && error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
            this.finishedRequest = true;
          }
        });
    }
  }

  getDropdownAprovadoresV2(): void {
    if(this.parametros) {
      this.newSubs = this.gatilhoChamadoService.dropdownAprovadoresV2(this.parametros)
        .subscribe({
          next: (results) => {
            this.ctrl['itens'] = results.dropDown;
            this.ctrl['itens'].forEach((item, index) => {
              item.id = index + 1;
            });
            this.finishedRequest = true;
          }, error: (error) => {
            console.error(error);
            this.toastr.show(error && error?.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
            this.finishedRequest = true;
          }
        });
    }
  }

  getDropdownTipoVisualizacaoOp(): void {
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('NORMAL') },
      { id: 2, text: this.translate.instant('SOMENTE_CHAT') },
      { id: 3, text: this.translate.instant('SOMENTE_DASH') }
    ];
    this.finishedRequest = true;
  }

  getDataFromEntidade(): void {
    if (this.fromEditTicket) {
      if (this.canLoadResources) {
        this.loadResources();
      }
    } else {
      this.loadResources();
    }
  }

  loadResources(): void {
    this.finishedRequest = false;
    switch (this.entidade) {
      case 'selectComentarioTemplate':
        this.getDropdownComentarioTemplate()
        break;
      case 'selectStatusEmailDominio':
        this.getDropdownEmailDominio();
        break;
      case 'selectLicencaSoftware':
        this.getDropdownLicenca();
        break;
      case 'selectPrioridade':
        this.getSelectPrioridade();
        break;
      case 'selectWhiteLabelOnline':
        this.getIconeWhiteLabelOnline();
        break;
      case 'selectWhiteLabelOffline':
        this.getIconeWhiteLabelOffline();
        break;
      case 'selectTipoSelecaoIntegracao':
        this.getTipoSelecaoIntegracao();
        break;
      case 'selectSelecaoIntegracao':
        this.getSelecaoIntegracao();
        break;
      case 'selectVersaoClassificacao':
        this.getVersaoClassificacao();
        break;
      case 'selectGatilhoClassificacao':
        this.getGatilhoClassificacao();
        break;
      case 'selectConfiavelClassificacao':
        this.getConfiavelClassificacao();
        break;
      case 'selectTipoClassificacao':
        this.getTipoClassificacao();
        break;
      case 'selectTiposDeTemplates':
        this.getListaTiposDeTemplates();
        break;
      case 'selectMacros':
        this.getListaMacros();
        break;
      case 'selectDashboards':
        this.getListaDashboards();
        break;
      case 'selectTempoFiltroAvancado':
        this.getTempoFiltroAvancado();
        break;
      case 'selectFiltroAvancado':
        this.getFiltroAvancado();
        break;
      case 'selectSlaSolucao':
        this.getSlaSolucao();
        break;
      case 'selectSlaResposta':
        this.getSlaResposta();
        break;
      case 'selectDataFiltroAvancado':
        this.getDataFiltroAvancado();
        break;
      case 'selectStatus':
        this.getStatus();
        break;
      case 'selectExtensaoBibliotecaScript':
        this.getExtensaoBibliotecaScript();
        break;
      case 'selectSistemaOperacionalBibliotecaScript':
        this.getSistemaOperacionalBibliotecaScript();
        break;
      case 'selectPastasBibliotecaScript':
        this.getPastasBibliotecaScript();
        break;
      case 'selectServicos':
        this.getServicosIntegracao();
        break;
      case 'selectGatilhos':
        this.dropdownGatilhos();
        break;
      case 'selectClienteFaturamento':
        this.dropDownListClienteFaturamento();
        break;
      case 'selectMoedas':
        this.dropdownMoedas();
        break;
      case 'selectDropdownCategoriaSecundariaConfiguracaoContrato':
        this.dropdownCategoriaSecundariaConfiguracaoContrato();
        break;
      case 'selectDropdownCategoriaPrimariaConfiguracaoContrato':
        this.dropdownCategoriaPrimariaConfiguracaoContrato();
        break;
      case 'selectAberturaChamado':
        this.getDropdownAberturaChamado();
        break;
      case 'selectAlteracaoChamado':
        this.getDropdownAlteracaoChamado();
        break;
      case 'selectCategoriaBaseConhecimento':
        this.getCategoriaBaseConhecimento();
        break;
      case 'selectTokenizar':
        this.getTokenizar();
        break;
      case 'selectModelosChatGpt':
        this.obterModelosChatGpt();
        break;
      case 'selectTipoArtigoBaseConhecimento':
        this.getTipoArtigoBaseConhecimento();
        break;
      case 'selectSituacaoBaseConhecimento':
        this.getSituacaoBaseConhecimento();
        break;
      case 'selectClientes':
        this.getClientes();
        break;
      case 'selectUnidadeNegocio':
        this.getUnidadeNegocio();
        break;
      case 'selectEquipes':
        this.getEquipes();
        break;
      case 'selectSetor':
        this.getSetores();
        break;
      case 'selectSetorB2c':
        this.getSetoresB2c();
        break;
      case 'selectCategoriaPrimaria':
        this.getCategoriaPrimaria();
        break;
      case 'selectCategoriaSecundaria':
        this.getCategoriaSecundaria();
        break;
      case 'selectCategoriaPrimariaAberturaTicket':
        this.getCategoriaPrimariaAberturaTicket();
        break;
      case 'selectCategoriaSecundariaAberturaTicket':
        this.getCategoriaSecundariaAberturaTicket();
        break;
      case 'selectPeriodoChamado':
        this.getPeriodoChamado();
        break;
      case 'selectRepeticaoChamado':
        this.getRepeticaoChamado();
        break;
      case 'selectRepeticaoChamadoV2':
        this.getRepeticaoChamadoV2();
        break;
      case 'selectRepeticaoChamadoV2Mensal':
        this.getRepeticaoChamadoV2(true);
        break;
      case 'selectDiaChamado':
        this.getDiaChamado();
        break;
      case 'selectFrequenciaChamado':
        this.getFrequenciaChamado();
        break;
      case 'selectFrequenciaChamadoV2':
        this.getFrequenciaChamadoV2();
        break;
      case 'selectTipoChamado':
        this.getTipoChamado();
        break;
      case 'selectMesaTrabalho':
        this.getMesaTrabalho();
        break;
      case 'selectMesaTrabalhoUpdateAndCreateTicket':
        this.getDropDownMesasUpdateAndCreateTicket();
        break;
      case 'selectTecnico':
        this.getTecnicos();
        break;
      case 'selectTimezone':
        this.getTimezone();
        break;
      case 'selectDropdownPeriodoContrato':
        this.getDropdownPeriodoContrato();
        break;
      case 'selectDropdownMoedas':
        this.getDropdownMoedas();
        break;
      case 'selectDropdownDiaVencimentoContrato':
        this.getDropdownDiaVencimentoContrato();
        break;
      case 'selectDropdownDiaFaturamentoContrato':
        this.getDropdownDiaFaturamentoContrato();
        break;
      case 'selectGatilhosIntegracao':
        this.getGatilhosIntegracao();
        break;
      case 'selectServicosIntegracao':
        this.getServicosIntegracao();
        break;
      case 'selectCatalogoServicos':
        this.getDropdownCatalogoServicos();
        break;
      case 'selectTipoCondicoes':
        this.getTipoCondicoes();
        break;
      case 'selectTipoAcao':
        this.getTipoAcoes();
        break;
      case 'selectGatilhoAcao':
        this.getGatilhoAcao();
        break;
      case 'selectScript':
        this.getScript();
        break;
      case 'selectPerfis':
        this.getPerfis();
        break;
      case 'selectAcaoExpiraSenha':
        this.getAcaoExpiraSenha();
        break;
      case 'selectTipoSistemaOperacional':
        this.getTipoSistemaOperacional();
        break;
      case 'selectExpressoes':
        this.getExpressoes();
        break;
      case 'selectOSVersoes':
        this.getOSVersoes();
        break;
      case 'selectSoftware':
        this.getSoftwares();
        break;
      case 'selectAtualizarMassa':
        this.getAtualizarMassa();
        break;
      case 'selectPrioridadeChamado':
        this.getPrioridadeChamado();
        break;
      case 'selectTipoDispositivo':
        this.getDropdownOfTypes();
        break;
      case 'selectDispositivoVinculado':
        this.getDispositivoVinculado();
        break;
      case 'selectDispositivoVinculadoB2c':
        this.getDispositivoVinculadoB2c();
        break;
      case 'selectCondicaoEventoAlertas':
        this.getCondicaoEventoAlertas();
        break;
      case 'selectArteGrafica':
        this.getArteGrafica();
        break;
      case 'selectTipoMonitoramento':
        this.getTipoMonitoramento();
        break;
      case 'selectGatilhoAcaoLink':
        this.getGatilhoAcaoLink();
        break;
      case 'selectPeriodoLink':
        this.getPeriodoLink();
        break;
      case 'selectTipoContato':
        this.getTipoContato();
        break;
      case 'selectClienteContato':
        this.getClienteContato();
        break;
      case 'selectClienteContatoB2c':
        this.getClienteContatoB2c();
        break;
      case 'selectChamadosRelacionados':
        this.getChamadosRelacionados();
        break;
      case 'selectTipoTarefa':
        this.getTipoTarefa();
        break;
      case 'selectDropdownGrupos':
        this.getDropdownGrupos();
        break;
      case 'selectDropdownTipos':
        this.getDropdownTipos();
        break;
      case 'selectDropdownObrigatorios':
        this.getDropdownObrigatorios();
        break;
      case 'selectTemplate':
        this.getDropdownTemplates();
        break;
      case 'selectContatosCliente':
        this.getDropdownContatosCliente();
        break;
      case 'selectAnexosChamado':
        this.getDropdownAnexosChamado();
        break;
      case 'selectProdutosPecas':
        this.getDropdownProdutosPecas();
        break;
      case 'selectTicketsConciliados':
        this.getDropdownTicketsConciliados();
        break;
      case 'selectUsuarioTelaIncial':
        this.getUserTelaInicial();
        break;
      case 'selectBooleano':
        this.getBooleano();
        break;
      case 'selectBooleanoRegra':
        this.getBooleanoRegra();
        break;
      case 'selectSimNao':
        this.getSimNao();
        break;
      case 'selectAtivoInativo':
        this.getAtivoInativo();
        break;
      case 'selectDispositivosLocados':
        this.getDispositivosLocados();
        break;
      case 'selectClienteEndereco':
      case 'selectEnderecoId':
        this.getClienteEndereco();
        break;
      case 'selectGatilhoChamadoTipo':
        this.getGatilhoChamadoTipo();
        break;
      case 'selectGatilhoChamadoCondicao':
        this.getGatilhoChamadoCondicao();
        break;
      case 'selectGatilhoChamadoValor':
        this.getGatilhoChamadoValor();
        break;
      case 'selectGatilhoChamadoOperador':
        this.getGatilhoChamadoOperador();
        break;
      case 'selectGatilhoChamadoEOuOperadores':
        this.getGatilhoChamadoOperadores();
        break;
      case 'selectGatilhoChamadoTipoAcao':
        this.getGatilhoChamadoTipoAcao();
        break;
      case 'selectTipoAtendimento':
        this.getTipoAtendimento();
        break;
      case 'selectGatilhoChamadoAcao':
        this.getGatilhoChamadoAcao();
        break;
      case 'selectGatilhoChamadoDestinatario':
        this.getGatilhoChamadoDestinatario();
        break;
      case 'selectChecklist':
        this.getChecklist();
        break;
      case 'selectTipoDistribuicao':
        this.getTipoDistribuicao();
        break;
      case 'selectTipoAutomacao':
        this.getTipoAutomacao();
        break;
      case 'workflowQuando':
        this.getWorkflowQuando();
        break;
      case 'selectDiasSemana':
        this.getDiasSemana();
        break;
      case 'selectDiaMes':
        this.getDiasMes();
        break;
      case 'selectGatilhoDispositivoCondicao':
        this.getDispositivoCondicao();
        break;
      case 'selectGatilhoDispositivoOperador':
        this.getDispositivoOperador();
        break;
      case 'customContent':
        this.setCustomContent();
        break;
      case 'selectFonts':
        this.getFonts();
        break;
      case 'selectScriptWorkflow':
        this.getWorkflowScript();
        break;
      case 'perfilDispositivo':
        this.getPerfilDispositivo();
        break;
      case 'getFilasChat':
        this.getFilasChat();
        break;
      case 'getTecnicosOnOff':
        this.getTecnicosOnOff();
        break;
      case 'selectPais':
        this.getDropdownPaises();
        break;
      case 'selectClientesPerfil':
        this.getClientes();
        break;
      case 'selectHorarioExpediente':
        this.getHorarioExpediente();
        break;
      case 'selectAllClients':
        this.getClientes();
        break;
      case 'selectMotivoPausa':
        this.getMotivoPausa();
        break;
      case 'selectDropdownFiltroAvancado':
        this.getDropdownFiltroAvancado();
        break;
      case 'selectDispositivo':
        this.getDropdownOfTypes();
        break;
      case 'selectClientesFormulario':
        this.getClientes();
        break;
      case 'selectTipoSolicitante':
        this.getTipoSolicitante();
        break;
      case 'selectEntidadeRelatorio':
        this.getDropdownEntidadeRelatorio();
        break;
      case 'selectTamanhoColuna':
        this.getDropdownTamanhoColuna();
        break;
      case 'selectDinamico':
        this.getDropdownDinamico();
        break;
      case 'selectOperador':
        this.getDropdownOperador();
        break;
      case 'selectOrdenacao':
        this.getDropdownOrdenacao();
        break;
      case 'selectValorRelatorioPersonalizado':
        this.getDropdownValorRelatorioPersonalizado();
        break;
      case 'selectNomeColuna':
        this.getDropdownNomeColuna();
        break;
      case 'selectNomeCampo':
        this.getDropdownNomeCampo();
        break;
      case 'selectValorFiltroRelatorioPersonalizado':
        this.getDropdownValorRelatorioPersonalizado();
        break;
      case 'selectGatilhoAlertaTipo':
        this.getDropdownGatilhoAlertaTipo();
        break;
      case 'selectGatilhoAlertasEOuOperadores':
        this.getDropdownOperadoresAlerta();
        break;
      case 'selectGatilhoAlertasCondicao':
        this.getDropdownCondicaoAlertas();
        break;
      case 'selectAlertaTipo':
        this.getDropdownAlertaTipo();
        break;
      case 'selectGatilhoAlerta':
        this.getDropdownGatilhoAlerta();
        break;
      case 'selectDispositivoFormularioAbertura':
        this.getDropdownDispositivoFormularioAbertura();
        break;
      case 'selectProcessadorMac':
        this.getProcessadorMac();
        break;
      case 'selectValorTipoPessoa':
        this.getValorTipoPessoa();
        break;
      case 'selectMesesAvaliacao':
        this.getDropdownMesesAvaliacao();
        break;
      case 'selectTipoCategorias':
        this.getTipoCategorias();
        break;
      case 'selectCategoriaPersonalizada':
        this.getCategoriaPrimariaPersonalizada();
        break;
      case 'selectSubCategoriaPersonalizada':
        this.getCategoriaSecundariaPersonalizada();
        break;
      case 'selectChamadosConciliadosSimplified':
        this.getDropdownChamadosConciliadosSimplified();
        break;
      case 'selectProvedor':
        this.getDropdownProvedor();
        break;
      case 'selectPlanos':
        this.getDropdownPlanos();
        break;
      case 'selectFormularioAberturaTicket':
        this.getDropdownFormularioAberturaTicket();
        break;
      case 'selectWhatsappFormulario':
        this.getDropdownWhatsappFormulario();
        break;
      case 'selectWhatsappFormularioB2C':
        this.getDropdownWhatsappFormularioB2C();
        break;
      case 'selectTempoEstagnado':
        this.getDropdownTempoEstagnado();
        break;
      case 'selectCodigoDdiPais':
        this.getDropDownCodigoDdiPais();
        break;
      case 'selectTipoContratoLimite':
        this.getTipoContratoLimite();
        break;
      case 'selectVisibilidadeFiltros':
        this.getVisibilidadeFiltros();
        break;
      case 'selectEntidadeFiltros':
        this.getDropdownEntidadeFiltros();
        break;
      case 'selectCamposFiltros':
        this.getDropdownCamposFiltros();
        break;
      case 'selectOperadoresFiltros':
        this.getDropdownOperadoresFiltros();
        break;
      case 'selectValoresCondicaoFiltro':
        this.getDropdownValoresCondicaoFiltros();
        break;
      case 'selectDataDinamicaCondicaoFiltro':
        this.getDropdownDataDinamicaCondicaoFiltro();
        break;
      case 'selectValorFiltroRelatorioPersonalizadoMultiSelect':
        this.getDropdownValorRelatorioPersonalizadoMultiSelect();
        break;
      case 'selectValorFiltroRelatorioPersonalizadoMultiSelectEditar':
        this.getDropdownValorRelatorioPersonalizadoMultiSelectEditar();
        break;
      case 'selectValorFiltroRelatorioPersonalizadoMultiSelectCriarTicket':
        this.getDropdownValorRelatorioPersonalizadoMultiSelectCriarTicket();
        break;
      case 'selectValorFormularioAberturaTicketMultiSelectTicket':
        this.getDropdownValorFormularioAberuraTicket();
        break;
      case 'selectDropdownValorCamposListaCondicao':
        this.getDropdownValorCamposListaCondicao();
        break;
      case 'selectCamposLista':
        this.getDropdownValorCamposLista();
        break;
      case 'selectAprovacaoTicket':
        this.getDropdownAprovacaoTicket();
        break;
      case 'selectAprovadoresTicket':
        this.getDropdownAprovadoresTicket();
        break;
      case 'selectBusinessUnitCustom':
        this.getBusinessUnitCustom();
        break;
      case 'selectBusinessUnitCustomB2c':
        this.getBusinessUnitCustomB2c();
        break;
      case 'selectGrupoSenha':
        this.getGroupPassword();
        break;
      case 'selectGroupCategories':
        this.getCategoriesGroups();
        break;
      case 'selectRemoteAccessCustomType':
        this.getRemoteAccessCustomType();
        break;
      case 'selectDeviceGroups':
        this.getDeviceGroupsByCustomerId();
        break;
      case 'selectDistribuicaoAutomatica':
        this.getDistribuicaoAutomatica();
        break;
      case 'selectTipoCampoPersonalizado':
        this.getTipoCampoPersonalizado();
        break;
      case 'selectDropdownCamposCustomizaveis':
        this.getCamposCustomizaveis();
        break;
      case 'selectWhatsappIntegrationType':
        this.getWhatsappIntegrationType();
        break;
      case 'selectWppOfficialTemplate':
        this.getWppOfficialTemplates();
        break;
      case 'selectDropdownCondicaoRegraCamposCustomizaveis':
        this.getCondicaoRegraCamposCustomizaveis();
        break;
      case 'selectDropdownCamposCustomizaveisRegra':
        this.getCamposCustomizaveisRegra();
        break;
      case 'selectExpedientes':
        this.getExpedientes();
        break;
      case 'severidadeAlertaAcronis':
        this.getSeveridadeAlertaAcronis();
        break;
      case 'categoriaAcronis':
        this.getCategoriasAcronis();
        break;
      case 'acoesAlertasAcronis':
        this.getAcoesAlertasAcronis();
        break;
      case 'selectWorkflowChat':
        this.getWorkflowsChat();
        break;
      case 'selectWorkflowWhatsapp':
        this.getWorkflowsWhatsapp();
        break;
      case 'selectChamadoConciliar':
        this.getChamadosConciliar();
        break;
      case 'selectNumerosWorkflow':
        this.getDropdownNumerosWorkflow();
        break;
      case 'selectTemplateOS':
        this.getTemplateOS();
        break;
      case 'selectAppsCadastroContato':
        this.getDropdownAppsContato();
        break;
      case 'selectTipoContatoSolicitante':
        this.getTipoContatoSolicitante();
        break;
      case 'selectConfiguracaoAlerta':
        this.getConfiguracaoAlerta();
      case 'selectStatusWorkflow':
        this.getDropdownWorkflowstatus();
        break;
      case 'selectTempoGravacao':
        this.getDropdownTempoGravacao();
        break;
      case 'selectNumeroSaida':
        this.getDropDownNumerosSaida();
        break;
      case 'selectQuandoV2':
        this.getDropdownQuandoV2();
        break;
      case 'selectTipoAprovacao':
        this.getDropdownTipoAprovacao();
        break;
      case 'selectTipoAprovadorV2':
        this.getDropdownTipoAprovadoresV2();
        break;
      case 'selectAprovadoresV2':
        this.getDropdownAprovadoresV2();
        break;
      case 'selectStatusWorkflow':
        this.getDropdownWorkflowstatus();
        break;
      case 'selectTipoVisualizacaoOperador':
        this.getDropdownTipoVisualizacaoOp();
        break;
    }
  }

  getDropDownNumerosSaida() {
    this.newSubs = this.chatService.obterIntegracaoAtivaSaida().subscribe(
      {
        next: (result) => {
          result.dropDown
          .map(item => {
            item['text'] = item.nome_fila  + ' - ' +  this.formatPhoneNumber(item.numero.slice(2)),
            item['integracao_id'] = item.id,
            item['id'] = result.dropDown.indexOf(item) + 1
          })
          this.ctrl['itens'] = result.dropDown;
          this.finishedRequest = true;
          this.retornoValores.emit(result.dropDown);
        }, error: (error) => {
          console.error(error);
          this.finishedRequest = true;
        }
      }
    )
  }

  getCamposCustomizaveisRegra(): void {
    let tipo_pesquisa = this.parametros;
    this.newSubs = this.camposCustomizaveisService.dropDownCamposRegra(tipo_pesquisa).subscribe(
      {
        next: (result) => {
          this.ctrl['itens'] = result.dropDown;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.finishedRequest = true;
        }
      }
    )
  }

  getCondicaoRegraCamposCustomizaveis(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: "=", text: this.translate.instant('IGUAL_CONDICAO') },
      { id: "<>", text: this.translate.instant('DIFERENTE').toLowerCase() }
    ]
  }

  getTipoCampoPersonalizado(): void {
    this.newSubs = this.camposCustomizaveisService.dropdownTipos().subscribe(
      {
        next: (result) => {
          this.ctrl['itens'] = result;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.finishedRequest = true;
        }
      }
    )
  }

  getDistribuicaoAutomatica(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 0, text: this.translate.instant('SEM_DISTRIBUICAO') },
      { id: 1, text: this.translate.instant('ALEATORIA') },
      { id: 2, text: this.translate.instant('OCIOSA') },
    ];
  }

  getDeviceGroupsByCustomerId() {
    if (this.parametros) {
      this.newSubs = this.deviceService
        .getDropdownOfGroupsByCustomerId(this.parametros)
        .subscribe(
          {
            next: (result) => {
              this.ctrl['itens'] = result.dropDown;
              this.finishedRequest = true;
            }, error: (error) => {
              this.toastr.show(error, '', environment.toastrConfig('danger'));
              this.finishedRequest = true;
            }
          });
    }
  }

  getRemoteAccessCustomType(): void {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('CLIENTE') },
      { id: 2, text: this.translate.instant('GRUPO_DISPOSITIVOS') },
      { id: 3, text: this.translate.instant('DISPOSITIVO') },
    ];
  }

  getGroupPassword() {
    var _config = environment.getPaginationDefault();
    _config.total_registros = 50
    this.newSubs = this.senhasService.dropdownGroupPassword({ is_ativo: true }).subscribe(
      {
        next: (result) => {
          this.ctrl['itens'] = result;
          this.finishedRequest = true;
        }, error: (error) => {
          this.toastr.show(error, '', environment.toastrConfig('danger'));
          this.finishedRequest = true;
        }
      }
    )
  }

  getDropdownAprovacaoTicket() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('INTERNA') },
      { id: 2, text: this.translate.instant('INTERNA_EXTERNA') },
    ]
  }

  getDropdownAprovadoresTicket() {
    this.finishedRequest = true;
    if(this.parametros) {
      this.ctrl['itens'] = [
        { id: 1, text: this.translate.instant('APROVADORES_INTERNO') }
      ];
    } else {
      this.ctrl['itens'] = [
        { id: 1, text: this.translate.instant('APROVADORES_INTERNO') },
        { id: 2, text: this.translate.instant('APROVADORES_EXTERNO') },
        { id: 3, text: this.translate.instant('APROVADORES_INTERNO_EXTERNO') },
      ];
    }
  }

  getTipoContratoLimite() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('VALOR') },
      { id: 2, text: this.translate.instant('PERCENTUAL_DO_CONTRATO') },
    ]
  }

  getDropDownCodigoDdiPais() {
    this.newSubs = this.clienteContatoService.dropDownCodigoDdiPais().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
        this.loading = false;
      }, (error) => {
        console.error(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'))
        this.finishedRequest = true;
        this.loading = false;
      }
    )
  }

  getDropdownWhatsappFormulario() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('IDENTIFICADO_EMAIL') },
      { id: 2, text: this.translate.instant('IDENTIFICADO_CNPJ') },
      { id: 3, text: this.translate.instant('IDENTIFICADO_EMAIL_DEPOIS_CNPJ') },
    ];
  }

  getDropdownWhatsappFormularioB2C(): void {
    this.newSubs = this.formularioService.dropDownIdentificadoresWpp()
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = results.dropDown;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'))
          this.finishedRequest = true;
        }
      });
  }

  getDropdownFormularioAberturaTicket() {
    this.newSubs = this.formularioService.FormularioDropdownWhatsapp().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropdown;
        this.finishedRequest = true;
      }, (error) => {
        console.error(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'))
        this.finishedRequest = true;
      }
    )
  }

  getDropdownTempoEstagnado() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('MINUTOS') },
      { id: 2, text: this.translate.instant('DIAS') },
      { id: 3, text: this.translate.instant('SEMANAS') },
    ]
  }

  getDropdownDispositivoFormularioAbertura() {
    var _params = {
      is_ativo: true,
      cliente_id: this.parametros
    }

    this.newSubs = this.listagemAlertasService.getDropdownList(_params).subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      }, (error) => {
        console.error(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownEntidadeRelatorio() {
    this.newSubs = this.relatorioPersonalizadoService.dropdownEntidades().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.finishedRequest = true;
      }, (error) => {
        console.error(error);
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      }
    )
  }

  getDropdownDinamico() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('DINAMICO'), value: true },
      { id: 2, text: this.translate.instant('NAO_DINAMICO'), value: false },
    ]
  }

  getProcessadorMac() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 2, text: "Intel", value: 'intel' },
      { id: 3, text: "AMD (M1, M2 etc.)", value: 'arm' },
    ]
  }

  getDropdownOperador() {
    this.finishedRequest = true;
    this.ctrl['itens'] = this.parametros;
  }

  checkTeste(valor) {
    return valor === 21
  }

  getDropdownValorRelatorioPersonalizadoMultiSelectEditar() {
    setTimeout(() => {
      this.ctrl['itens'] = this.parametros && this.parametros.lista ? this.parametros.lista : [];
      this.finishedRequest = true;
    }, 500)
  }

  getDropdownValorRelatorioPersonalizadoMultiSelect() {
    setTimeout(() => {
      this.ctrl['itens'] = this.parametros;
      this.finishedRequest = true;
    }, 500)
  }

  getDropdownValorCamposLista() {
    this.newSubs = this.camposCustomizaveisService.obterInformacoesRegra(this.atributo).subscribe(
      (result) => {
        if (result) {
          this.ctrl['itens'] = result && result.lista ? result.lista : result.opcoes ? result.opcoes : [];
        }
        this.finishedRequest = true;
        this.loading = false;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.log(error);
        this.finishedRequest = true;
        this.loading = false;
      }
    )
  }

  getDropdownValorCamposListaCondicao() {
    this.newSubs = this.camposCustomizaveisService.obterInformacoesRegra(this.parametros).subscribe(
      (result) => {
        if (result) {
          this.ctrl['itens'] = result && result.lista ? result.lista : result.opcoes ? result.opcoes : [];
        }
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.log(error);
        this.finishedRequest = true;
      }
    )
  }

  getDropdownValorFormularioAberuraTicket() {
    this.newSubs = this.camposCustomizaveisService.campoPersonalizadoListaOpcaoObtemTodas(this.parametros).subscribe(
      (result) => {
        this.ctrl['itens'] = result;
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getDropdownValorRelatorioPersonalizadoMultiSelectCriarTicket(value?) {
    if (value) {
      if (value.opcoes) {
        this.ctrl['itens'] = value.opcoes;
        return;
      }
      const campo_personalizado = (typeof value === 'object' && value.id) ? value.id : value;
      this.newSubs = this.camposCustomizaveisService.campoPersonalizadoListaOpcaoObtemTodas(campo_personalizado)
       .subscribe({
         next: response => {
           this.parametros = response;
           this.ctrl['itens'] = this.parametros;
           this.finishedRequest = true;
           console.log(response)
         }
       })

    }
  }

  getDropdownValorRelatorioPersonalizado() {
    this.ctrl['itens'] = this.parametros;
    this.finishedRequest = true;
  }

  getDropdownNomeColuna() {
    this.newSubs = this.relatorioPersonalizadoService.dropdownColunas(this.entidadeRelatorioPersonalizado).subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.ctrl['itens'].map((element) => {
          element.id = element.id === 0 ? (element.chave.split('@')[1]) : element.id
        })
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getDropdownNomeCampo() {
    this.newSubs = this.relatorioPersonalizadoService.dropdownFiltros(this.entidadeRelatorioPersonalizado).subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown
        this.ctrl['itens'].map((element) => {
          element.id = element.id === 0 ? (element.chave.split('@')[1]) : element.id
        })
        this.finishedRequest = true;
      }, (error) => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error);
        this.finishedRequest = true;
      }
    )
  }

  getDropdownTamanhoColuna() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 10, text: '10%' },
      { id: 20, text: '20%' },
      { id: 30, text: '30%' },
      { id: 40, text: '40%' },
      { id: 50, text: '50%' },
      { id: 60, text: '60%' },
      { id: 70, text: '70%' },
      { id: 80, text: '80%' },
      { id: 90, text: '90%' },
      { id: 100, text: '100%' },
    ]
  }

  getDropdownOrdenacao() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, value: 'asc', text: this.translate.instant('ORDENACAO_CRESCENTE') },
      { id: 2, value: 'desc', text: this.translate.instant('ORDENACAO_DECRESCENTE') },
    ]
  }

  setCustomContent() {
    this.ctrl['itens'] = this.parametros;
    this.finishedRequest = true;
  }

  getDiasMes() {
    let i;
    for (i = 1; i < 32; i++) {
      this.ctrl['itens'].push({
        id: i, text: i
      })
    }
  }

  getTipoAutomacao() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('EXECUCAO_SCRIPT') },
      { id: 2, text: this.translate.instant('MONITORAMENTO_SERVICO_PARADO') },
    ]
  }

  getDropdownTipos() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 2, text: this.translate.instant('STRING') },
      { id: 3, text: this.translate.instant('DATE') },
      { id: 4, text: this.translate.instant('INTEGER') },
      { id: 5, text: this.translate.instant('BOOLEAN') },
      { id: 6, text: this.translate.instant('DECIMAL') },
    ];
  }

  getDiasSemana() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('SEGUNDA') },
      { id: 2, text: this.translate.instant('TERCA') },
      { id: 3, text: this.translate.instant('QUARTA') },
      { id: 4, text: this.translate.instant('QUINTA') },
      { id: 5, text: this.translate.instant('SEXTA') },
      { id: 6, text: this.translate.instant('SABADO') },
      { id: 7, text: this.translate.instant('DOMINGO') },
    ];
  }

  getFilasChat() {
    this.newSubs = this.chatService.getFilasChat(this.parametros)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getTecnicosOnOff() {
    this.newSubs = this.chatService.getTecnicosOnOff()
      .subscribe(results => {
        let lista = results.listagemTecnicoOnOff.map((data) => {
          return { id: data.id, text: data.nome }
        })
        this.ctrl['itens'] = lista;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getAntivirus(filtro?) {
    this.newSubs = this.antivirusService.getAntivirus(filtro)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getWorkflowQuando() {
    this.newSubs = this.deviceService.getDropdownOfTriggerType().subscribe(
      (result) => {
        this.ctrl['itens'] = result.dropDown;
        this.finishedRequest = true;
      },
      (error) => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDispositivoCondicao() {
    this.newSubs = this.deviceService.getDropdownOfConditions()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getPerfilDispositivo() {
    this.newSubs = this.deviceService.getDropdownOfMachineProfiles()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getDispositivoOperador() {
    this.newSubs = this.deviceService.getDropdownOfOperators()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getWorkflowScript() {
    this.newSubs = this.deviceService.getDropdownOfScripts()
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        this.finishedRequest = true;
      }, error => {
        console.error(error)
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        this.finishedRequest = true;
      })
  }

  getHorarioExpediente() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: '24/7' },
      { id: 2, text: this.translate.instant('COMERCIAL') }
    ];
  }

  getTipoCategorias() {
    this.finishedRequest = true;
    this.ctrl['itens'] = [
      { id: 1, text: this.translate.instant('PUBLICO'), value: true },
      { id: 2, text: this.translate.instant('PRIVADO'), value: false }
    ];
  }

  getCategoriaPrimariaPersonalizada(): void {
    var _params = {
      /* não tem flag na api */
      todos_clientes: false,
      is_ativo: true
    };
    this.newSubs = this.categoriasService.getDropDownCategoriaPrimaria(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        if (this.adicionaAll && this.adicionaAll === true) {
          this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
        }
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  getCategoriaSecundariaPersonalizada(): void {
    var _params = {
      is_ativo: true,
      todos_clientes: false,
      categoria_primaria_id: this.parametros,
    };
    this.newSubs = this.categoriasService.getDropDownCategoriaSecundariaPersonalizada(_params)
      .subscribe(results => {
        this.ctrl['itens'] = results.dropDown;
        if (this.adicionaAll && this.adicionaAll === true) {
          if (this.ctrl['itens'].length > 1) {
            this.ctrl['itens'].unshift({ id: '__all__', text: this.translate.instant("TODOS") });
          }
        }
        this.finishedRequest = true;
      }, error => {
        this.toastr.show(error, '', environment.toastrConfig('danger'));
        console.error(error)
        this.finishedRequest = true;
      })
  }

  redirecionaTelaClientes(): void {
    this.route.navigate(['/registration/customers-list']);
  }

  getCamposCustomizaveis(): void {
    this.newSubs = this.camposCustomizaveisService
      .buscarCamposCustomizaveisEmpresa()
      .subscribe(
        (results) => {
          this.ctrl['itens'] = results.campos;
          this.finishedRequest = true;
        },
        (error) => {
          this.toastr.show(error, "", environment.toastrConfig("danger"));
          this.finishedRequest = true;
        }
      );
  }

  verifyLoadResources(): void {
    setTimeout(() => {
      const selectElement = this.select.element.querySelector('.scroll-host');
      if (selectElement && selectElement.closest('.modal')) {
        selectElement.addEventListener('scroll', (event: Event) => this.scrollToEndModal(event));
      }
    }, 500)
    if (!this.canLoadResources && this.fromEditTicket) {
      this.spinner.show();
      this.canLoadResources = true;
      this.getDataFromEntidade();
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    }
  }

  getTemplateOS(): void {
    this.newSubs = this.templatesService.getDropdownTemplate(4)
      .subscribe({
        next: (results) => {
          this.ctrl['itens'] = [];
          if (results.dropDown.length > 0) {
            results.dropDown.forEach((element) => {
              this.ctrl['itens'].push(element);
            });
          }

          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error && error.error ? error.error : error, '', environment.toastrConfig('danger'));
        }
      });
  }

  openModalAdicionaContato(): void {
    const modalInstance = this.modalService.open(ModalContatosComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-custom-milvus',
      size: 'medio'
    });

    modalInstance.result.then((results) => {
      if(results) {
        this.getDataFromEntidade();
      }
    });
  }

  verificaComplementoSelectSolicitante(item): string {
    let complemento = [];
    switch(item.tipo_solicitante_id) {
      case 1:
        if(item.cnpj_cpf) {
          complemento.push(this.mask.applyMask(item.cnpj_cpf, item.is_fisica ? '000.000.000-00' : '00.000.000/0000-00'));
        }
        if(item.endereco) {
          complemento.push(item.endereco);
        }
        return complemento.join(' - ');
      case 2:
        if(item.cpf) {
          complemento.push(this.mask.applyMask(item.cpf, '000.000.000-00'));
        }
        if(item.email) {
          complemento.push(item.email);
        }
        if(item.celular && item.celular !== 'null') {
          complemento.push(this.mask.applyMask(item.celular, '(00) 00000-0000'));
        }
        if(item.telefone && item.telefone !== 'null') {
          complemento.push(this.mask.applyMask(item.telefone, '(00) 0000-0000'));
        }
        return complemento.join(' - ');
      case 3:
        if(item.username) {
          complemento.push(item.username);
        }
        if(item.celular && item.celular !== 'null') {
          complemento.push(this.mask.applyMask(item.celular, '(00) 00000-0000'));
        }
        if(item.telefone && item.telefone !== 'null') {
          complemento.push(this.mask.applyMask(item.telefone, '(00) 0000-0000'));
        }
        return complemento.join(' - ');
    }
  }

  verificaFiltro(): void {
    if(this.is_filtrado) {
      this.loading = true;
      this.filtrarPersonalizado({term: ''});
      setTimeout(() => {
        this.loading = false;
      }, 1000)
    }
  }

  retornaLblCustomMulti(id) {
    let txt = ''
    this.ctrl['itens'].forEach(item => {
      if(item.id === id) {
        txt = item.abb;
      }
    });
    
    return txt;
  }

  getDropdownTempoGravacao(){
    this.newSubs = this.acessoRemotoService.getTempoGravacao().subscribe(
      {
        next: (result) => {
          this.ctrl['itens'] = result;
          this.finishedRequest = true;
        }, error: (error) => {
          console.error(error);
          this.finishedRequest = true;
        }
      }
    )
  }

  getTooltipText() {
    const value = this.ctrl['itens'].find(i => i.id === this.ctrl['selectedValue']);
    if (value) {
      return value.text || value.label || value.nome || value.descricao
    }
  }

  formatPhoneNumber(phone: string): string {
    const phoneLength = phone.length;
  
    if (phoneLength > 10 && phone.includes('0800')) {
      return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 11)}`;
    }
  
    switch (phoneLength) {
      case 4:
        return `${phone}`;
      case 5:
        return `${phone}`;
      case 6:
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)}`;
      case 7:
        return `${phone.slice(0, 3)} ${phone.slice(3, 7)}`;
      case 8:
        return `${phone.slice(0, 4)} ${phone.slice(4, 8)}`;
      case 9:
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6, 9)}`;
      case 10:
        return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)} ${phone.slice(6, 10)}`;
      case 11:
        return `(${phone.slice(0, 2)})${phone.slice(2, 3)} ${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
      case 12:
        return `${phone.slice(0, 4)} ${phone.slice(4, 8)} ${phone.slice(8, 12)}`;
      case 13:
        return `${phone.slice(0, 4)} ${phone.slice(4, 8)} ${phone.slice(8, 13)}`;
      default:
        return phone; // Retorna o número sem formato caso o comprimento não corresponda a nenhum caso
    }
  }

}
