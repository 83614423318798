<div class="modal-content modal-milvus">
  <div class="modal-header">
    <h6 class="modal-title" id="modal-title-default">
      {{ "CHAMAR_CONTATO_WHATS" | translate }}
    </h6>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <i class="mdi mdi-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <h6 class="modal-title">{{ "PESQUISA" | translate }}</h6>
          <button
            type="button"
            class="btn btn-success badge-pill"
            [disabled]="!permissao.EditarCliente ? !permissao.CriarNovoContatoChat : false"
            (click)="abrirModalContato()"
          >
            <span>{{ "NOVO_CONTATO" | translate }}</span>
          </button>
        </div>
        <div class="row" style="margin-top: -10px;">
          <div class="col-12">
            <div
              class="navbar-search navbar-search-light form-inline my-3 mr-lg-3"
              id="navbar-search-main"
            >
              <div class="form-group mb-0" style="width: 100%">
                <div class="input-group input-group-merge" style="width: 100%">
                  <div class="input-group-prepend">
                    <span class="input-group-text"
                      ><i class="fas fa-search"></i
                    ></span>
                  </div>
                  <input
                    class="form-control"
                    placeholder="{{ 'PLACEHOLDER_NOME_CLIENTE_CPF_CELULAR_TELEFONE' | translate }}"
                    type="text"
                    name="search"
                    [(ngModel)]="searchText"
                    (ngModelChange)="changePesquisa($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive table-listagem">
          <table class="table align-items-center table-flush" style="margin-bottom: 0; overflow: hidden;">
            <thead class="thead-light">
              <tr>
                <th scope="col" [translate]="'CONTATO'"></th>
                <th *ngIf="solicitantes_habilitados.solicitante_contato || solicitantes_habilitados.solicitante_operador" scope="col">CPF</th>
                <th *ngIf="solicitantes_habilitados.solicitante_cliente" scope="col" [translate]="'CLIENTE'"></th>
                <th scope="col" [translate]="'CELULAR'"></th>
                <th scope="col" [translate]="'TELEFONE'"></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of lista">
                <td>
                  <div container=".modal" [ngbTooltip]="item.descricao && item.descricao.length > 20 ? item.descricao : null">
                    {{ item.descricao | ellipsis : 20 }}
                  </div>
                </td>
                <td *ngIf="solicitantes_habilitados.solicitante_contato || solicitantes_habilitados.solicitante_operador">{{ item.cpf | mask: '000.000.000-00' | null }}</td>
                <td *ngIf="solicitantes_habilitados.solicitante_cliente">
                  <div container=".modal" [ngbTooltip]="item.cliente_nome && item.cliente_nome.length > 20 ? item.cliente_nome : null">
                    {{ item.cliente_nome | ellipsis : 20 | null }}
                  </div>
                </td>
                <td>{{ item.celular | phone : item.codigo_pais | null }}</td>
                <td>{{ item.telefone | phone : item.codigo_pais_telefone | null }}</td>
                <td class="text-right">
                  <button
                    type="button"
                    class="btn btn-success rounded-circle btn-icon-only"
                    [disabled]="!permissao.EditarCliente ? !permissao.EditarContatoChat : false"
                    (click)="abrirModalContato(item)"
                    container=".modal" [ngbTooltip]="('EDITAR_CONTATO' | translate)"
                  >
                    <i class="fa-solid fa-pen"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-darkblue badge-pill"
                    (click)="abrirConversaPorSaida(item)"
                  >
                    <span>{{ "CONVERSAR" | translate }}</span>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td [colSpan]="(solicitantes_habilitados.solicitante_contato || solicitantes_habilitados.solicitante_operador) && solicitantes_habilitados.solicitante_cliente ? 6 : 5" style="font-size: 16px; padding: 0 14px 0 18px;">
                  <pagination
                    *ngIf="lista.length > 0"
                    [meta]="contatosPagination"
                    (pageChange)="paginarListagem($event)"
                  >
                  </pagination>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
