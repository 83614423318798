<div class="custom-modal-top">
    <div class="custom-modal-header">
        <h6 class="custom-modal-title">
            {{ (!templateMessage ? 'INICIAR_CONVERSA_WPP' : 'TEMPLATES_DO_META') | translate}} 
            {{' ' + (data.number | mask: getMaskByCountry(data.number))}}
        </h6>
        <button type="button" class="custom-close" (click)="close(false)">
            <i class="mdi mdi-close"></i>
        </button>
    </div>
    <div class="custom-modal-body">
        <!-- Linha de alerta com ícone e texto -->
        <div *ngIf="isToShowSpecificMessageFromMeta" class="alert-message">
            <i class="fa-regular fa-circle-exclamation alert-icon"></i>
            <span class="alert-text">
                Pode ser que o destinatário ainda não respondeu ao seu template, aguarde a resposta. Ou sua
                conversa pode estar expirada, por favor, envie novamente um template para continuar a conversa.
            </span>
        </div>
        <div class="aviso-numero" style="padding: 0 15px" *ngIf="integracoes && integracoes.length === 1">
            <h6 class="custom-modal-title mb-4">
                {{ 'INICIAR_CONVERSA_SAIDA_WPP' | translate: { numero: formatPhoneNumber(provedorSelecionado.numero.slice(2)), fila: provedorSelecionado.nome_fila } }} 
            </h6>
        </div>
        
        <div class="row form-group" style="padding: 0 15px">
            <div [ngClass]="{'col-6': providerId, 'col-12': !providerId || providerId === 2}" [ngStyle]="{'display': integracoes && integracoes.length === 1 ? 'none' : 'block'}">            
                <span style="font-weight: bolder">{{ "FILA_ATENDIMENTO" | translate }}</span>
                <select-padrao 
                  [entidade]="'selectNumeroSaida'" 
                  [returnObj]="true"
                  (selectChange)="escolhaSaida($event)"
                  (retornoValores)="retornoSelectIntegracoes($event)"
                >
                </select-padrao>
            </div>
            <div class="form-group" [ngClass]="{'col-6': integracoes && integracoes.length > 1, 'col-12': integracoes && integracoes.length === 1, }" *ngIf="providerId && providerId === 4 && providerId">
                <span style="font-weight: bolder">Template</span>
                <select-padrao 
                    [entidade]="'selectWppOfficialTemplate'"
                    [returnObj]="true"
                    [params]="integrationId" 
                    [disabled]="disabledDropDown"
                    (selectChange)="changeTemplateProperties($event)"
                    (retornoValores)="retornoSelectTemplatesMeta($event)"
                >
                </select-padrao>
            </div>
        </div>
        <ng-container *ngIf="providerId && providerId === 4; else zapfy">       
            <div *ngIf="template_content" class="custom-row mt-2">
                <div class="col-12 form-group">
                    <label [translate]="'MENSAGEM'"></label>
                    <br>
                    <span class="">{{template_content}}</span>
                </div>
            </div>
        </ng-container>
        <ng-template #zapfy>
            <div *ngIf="provedorSelecionado && provedorSelecionado.provider_id === 2" class="custom-row">
                <div class="col-12 form-group">
                    <label [translate]="'MENSAGEM'"></label>
                    <textarea class="form-control mt-2" rows="10" [(ngModel)]="content.mensagem"></textarea>
                </div>
            </div>
        </ng-template>        
    </div>
    <div class="custom-modal-footer">
        <button type="button" class="btn btn-success badge-pill" [translate]="'INICIAR_CONVERSA'" (click)="close(content)" [disabled]="verifyDisabled()"></button>
    </div>
</div>