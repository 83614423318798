import { Injectable } from '@angular/core';
import { Theme, light, dark } from './theme';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private active: Theme = light;
  private availableThemes: Theme[] = [light, dark];
  temaSubject$ =  new BehaviorSubject<Theme>(light);
  private messageInadimplencia = new BehaviorSubject("");
  currentMessageInadimplencia = this.messageInadimplencia.asObservable();

  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }

  isDarkTheme(): boolean {
    return this.active.name === dark.name;
  }

  setDarkTheme(): void {
    this.setActiveTheme(dark);
    window.localStorage.setItem("theme", "dark");
    this.temaSubject$.next(dark);
  }

  setLightTheme(): void {
    this.setActiveTheme(light);
    window.localStorage.setItem("theme", "light");
    this.temaSubject$.next(light);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;
    this.temaSubject$.next(theme)

    Object.keys(this.active.properties).forEach(property => {
      document.documentElement.style.setProperty(
        property,
        this.active.properties[property]
      );
    });
  }

  emiteMessageInadimplencia(message: string): void {
    this.messageInadimplencia.next(message);
  }
}
