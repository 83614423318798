import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TokenService } from "../core/token/token.service";
import { GatilhoDispositivo } from "../models/gatilho-dispositivo.model";
import { TipoDispositivo } from "../models/tipo-dispositivo.model";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  private apiUrl = environment.apiGestorUrlLocal;
  private apiVNC = environment.apiVNC;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      authorization: this.tokenService.getToken(),
      session: this.tokenService.getSessionUsuario(),
    }),
    params: {},
  };

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getConfigAlerta(filtro): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/configuracao-alerta/dispositivo-nivel`,
        filtro,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDispositivoAlertas(dispositivo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-alerta/dispositivo/${dispositivo_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownAlertasDispositivo(dispositivo_id, cliente_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/configuracao-alerta/dropdown-configuracoes-alertas/?dispositivo_id=${dispositivo_id}&cliente_id=${cliente_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getCustomFields(dispositivo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/campos-personalizados/${dispositivo_id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getAlertConfigById(dispositivo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracao-alerta/dispositivo/${dispositivo_id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateAlertConfigById(dispositivo_id, payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracao-alerta/dispositivo/${dispositivo_id}`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getUnitAlertConfigById(unitId): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/configuracao-alerta/unidade-disco/${unitId}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateUniAlertConfigById(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/configuracao-alerta/unidade-disco`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  solveAlertById(dispositivo_id, payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/Alerta/marcar-resolvido/dispositivo/${dispositivo_id}`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }
  filterSNMPMessageList(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/snmp/listagem-mensagens/dispositivo`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getMessageConfigBySNMPId(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/snmp/configuracao-mensagens/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setMessageConfigBySNMPId(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/snmp/configuracao-mensagens`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterList(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(`${this.apiUrl}api/dispositivos/list`, filtros, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  filterGroupList(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/dispositivos/grupos/lista`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setGroup(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/dispositivos/grupos`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateGroup(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/dispositivos/moveDispositivo`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getGeneralData(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/dispositivos/informacoes/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getGeneralDataPaginated(id): Observable<any> {
    this.httpOptions.params = { total_registros: 100 };
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/informacoes/paginado/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setGeneralData(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/dispositivos/`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  setGeneralDataCustomField(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/dispositivos/campo-personalizado`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getById(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/dispositivos/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getVersionHistory(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/listagem/historico-atualizacao/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getVersionClientStatus(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/dispositivos/versao/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getNumberOfTickets(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/dispositivos/qtdchamados/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getNumberOfAlerts(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/dispositivos/qtdalertas/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getDropdownOfGroupsByCustomerId(id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/grupos/dropDownGrupos/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfLinkeds(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/dropdown/vinculados`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownList(params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(`${this.apiUrl}api/dispositivos/dropdownlist`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getLogs(params, id): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(`${this.apiUrl}api/dispositivos/logs/${id}`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getAlertTypes(params, id): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/buscar/alertas-tipo/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getVerificationPortalPaginated(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/perfil-maquina/verificar/dispositivo/portal/paginado/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterAlertList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(`${this.apiUrl}api/Alerta/listDisp`, filter, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  filterPeripheralList(params, filters): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/dispositivo-perifericos/listagem`,
        filters,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterStorageList(params, filters): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/dispositivoDevices/listAll`,
        filters,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterAccountList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/DispositivoContas/listAll`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterServiceList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/dispositivoServicesWindows/listAll`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterVideoList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/PlacasDeVideo/listGrid`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterConnectionHistoryList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiVNC}apivnc/sessaoremota/lista-historico-conexao`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterPasswordList(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(`${this.apiUrl}api/senhas/list`, filtros, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  filterSoftwareList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/DispositivoProgramas/listAll`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterNetworkList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/DispositivoInterfaceNet/listAll`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterImageList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/dispositivo-anexo/listagem`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterDocumentList(params, filter): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/documentosdiversos/list`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getMonitoringGraphics(filter): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/monitoramento-dispositivo/grafico/metricas`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getMonitoringGraphicsDois(filter, params): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/monitoramento-dispositivo/grafico/metricas2`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getStorageGraphics(id_dispositivo: any, params: any): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .get(
        `${this.apiUrl}api/dispositivoDevices/grafico-mensal/${id_dispositivo}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getStorageGraphicsPer15Days(id_dispositivo: any, filter: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/dispositivoDevices/grafico-quinzenal/${id_dispositivo}`,
        filter,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setImage(image): Observable<any> {
    let httpOp = {
      headers: new HttpHeaders({ authorization: this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
      params: {},
    };

    let formData = new FormData();
    for (var item in image) {
      if(item === 'file') {
        image[item].forEach(file => {
          formData.append(item, file, file.name);
        });
      } else {
        formData.append(item, image[item]);
      }
    }

    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/dispositivo-anexo`, formData, httpOp)
      .pipe(catchError(this.handleError));
  }

  deleteImage(image_id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(
        `${this.apiUrl}api/dispositivo-anexo/` + image_id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setDocument(documento): Observable<any> {
    let httpOp = {
      headers: new HttpHeaders({ authorization: this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
      params: {},
    };
    let formData = new FormData();
    for (var item in documento) {
      if(item === 'file') {
        documento[item].forEach(file => {
          formData.append(item, file, file.name);
        });
      } else {
        formData.append(item, documento[item]);
      }
    }

    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/documentosdiversos`, formData, httpOp)
      .pipe(catchError(this.handleError));
  }

  deleteDocument(documento_id: string): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(
        `${this.apiUrl}api/documentosdiversos/` + documento_id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  sendOrderUpdate(listaDispositivosId): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/dispositivos/enviar-ordem-atualizacao`,
        listaDispositivosId,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterTypeList(params, filtros): Observable<any> {
    this.httpOptions.params = params;
    return this.http
      .post(
        `${this.apiUrl}api/tipo-dispositivo/listagem`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getTypeFields(id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/tipo-dispositivo/buscar-campos/` + id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getFields(params?): Observable<any> {
    this.httpOptions.params = "";

    if (params) {
      this.httpOptions.params = params;
    }

    return this.http
      .get(`${this.apiUrl}api/campo-dispositivo`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  getTypeById(id: number): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/tipo-dispositivo/` + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  set(dispositivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/dispositivos`, dispositivo, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  update(dispositivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/dispositivos`, dispositivo, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteById(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(`${this.apiUrl}api/dispositivos/` + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteUnknowScanned(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(
        `${this.apiUrl}api/dispositivos/escaneados/remover/` + id,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  deleteType(id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(`${this.apiUrl}api/tipo-dispositivo/` + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  updateRegisteredType(payload, id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/tipo-dispositivo/` + id,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setType(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/tipo-dispositivo/`, payload, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  uploadImage(file, tipo_dispositivo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/tipo-dispositivo/imagem/${tipo_dispositivo_id}`,
        file,
        { headers: new HttpHeaders({ authorization: this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }) }
      )
      .pipe(catchError(this.handleError));
  }

  newRemoteSession(dispositivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiVNC}apivnc/sessaoremota/solicita-acesso`, dispositivo, {
        headers: new HttpHeaders({ authorization: this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() }),
      })
      .pipe(catchError(this.handleError));
  }

  newRemoteSession2fa(payload): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiVNC}apivnc/sessaoremota/solicita-acesso-v2`, payload, {
        headers: new HttpHeaders({ authorization: this.tokenService.getToken(), 'session': this.tokenService.getSessionUsuario() })
      })
      .pipe(catchError(this.handleError));
  }

  resetClient(id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/reiniciar-servico/${id}`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfStatusType(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/dropdown/filtro-status-tipo`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfGroups(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/dispositivos/grupos/dropdownlist`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfMachineProfiles(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(`${this.apiUrl}api/perfil-maquina/dropdown`, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  filterMemoryList(filtros): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/dispositivo-memoria/listagem`,
        filtros,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  filterWorkflowList(data: any, parametros: any): Observable<any> {
    this.httpOptions.params = parametros;
    return this.http
      .post(
        `${this.apiUrl}api/gatilho-dispositivos/listagem`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfTriggerType(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/gatilho-dispositivos/dropdown-tipo-execucao`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfConditions(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/gatilho-dispositivos/dropdown-condicoes`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfOperators(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/gatilho-dispositivos/dropdown-operadores`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getTriggerInfo(chave: string): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/gatilho-dispositivos/obter-informacoes/` + chave,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfScripts(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/monitoramento-servicos/dropDownScripts`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  setWorkflow(workflow: GatilhoDispositivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/gatilho-dispositivos`,
        workflow,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateTrigger(workflow: GatilhoDispositivo): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(`${this.apiUrl}api/gatilho-dispositivos`, workflow, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  deleteWorkflow(id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .delete(`${this.apiUrl}api/gatilho-dispositivos/` + id, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  startTrigger(id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/gatilho-dispositivos/iniciar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  pauseTrigger(id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/gatilho-dispositivos/pausar/${id}`,
        "",
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  exportInventoryPDF(dados: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/dispositivos/exportar/pdf`, dados, {
        headers: this.httpOptions.headers,
        observe: "response",
        responseType: "blob",
      })
      .pipe(catchError(this.handleError));
  }

  scheduleReport(dados): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(
        `${this.apiUrl}api/agendamento-relatorio/exportar-dispositivo`,
        dados,
        {
          headers: this.httpOptions.headers,
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(catchError(this.handleError));
  }

  exportInventoryExcel(dados: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .post(`${this.apiUrl}api/dispositivos/exportar/xls`, dados, {
        headers: this.httpOptions.headers,
        observe: "response",
        responseType: "blob",
      })
      .pipe(catchError(this.handleError));
  }

  updateManual(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/dispositivos/alteraDispositivoManual`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateType(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .put(
        `${this.apiUrl}api/dispositivos/alterar-tipo`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  getDropdownOfTypes(): Observable<any> {
    this.httpOptions.params = "";
    return this.http
      .get(
        `${this.apiUrl}api/tipo-dispositivo/listagem/dropdown`,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  updateGetScreen(device_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiVNC}apivnc/sessaoremota/atualizar-getscreen/${device_id}`, '', this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  searchSoftwaresInstallable(searchTerm: string): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/DispositivoProgramas/buscar-instalaveis/${searchTerm}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  installSoftware(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/DispositivoProgramas/instalacao-silenciosa-solicitacao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateSoftwares(payload: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.put(`${this.apiUrl}api/DispositivoProgramas/atualizar-versao-solicitacao`, payload, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateErrorDetails(software_id: any): Observable<any> {
    this.httpOptions.params = "";
    return this.http.get(`${this.apiUrl}api/DispositivoProgramas/atualizar-versao-erro-log/${software_id}`, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  removeImage(tipo_dispositivo_id): Observable<any> {
    this.httpOptions.params = "";
    return this.http.post(`${this.apiUrl}api/tipo-dispositivo/excluir-imagem/${tipo_dispositivo_id}`, {}, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  getDropdownOfLinkedsB2c(filtro): Observable<any> {
    this.httpOptions.params = null;
    return this.http
      .post(
        `${this.apiUrl}api/b2c/dropdown-dispositivos`,
        filtro,
        this.httpOptions
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("Ops! Ocorreu um erro:", error.error.message);
    } else {
      console.error(
        `Backend retornou o código ${error.status}, ` +
          `o body é: ${error.error}`
      );
    }
    return throwError(error.error);
  }
}
