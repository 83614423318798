import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { WhatsappService } from 'src/app/services/whatsapp.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CountriesMask } from "src/app/variables/countries-phones";
import { ChatService } from 'src/app/services/chat.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-msg-start-chat-whatsapp',
  templateUrl: './modal-msg-start-chat-whatsapp.component.html',
  styleUrls: ['./modal-msg-start-chat-whatsapp.component.scss']
})
export class ModalMsgStartChatWhatsappComponent implements OnInit, OnDestroy {
  @Input() data;
  @Input() templateMessage: boolean;
  @Input() isToShowSpecificMessageFromMeta: boolean;
  content: any;
  template_content: any;
  newSubs: Subscription;
  providerId!: number;
  integrationId!: number;
  disabledDropDown: boolean = false;

  provedorSelecionado: any;
  numeroSaida: any;
  integracoes: any;
  chamadoId: Number;
  chamadoCodigo: number;
  templatesZapfy: any;

  constructor(
    private modal: NgbActiveModal,
    private whatsappService: WhatsappService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private chatService: ChatService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.chamadoId = this.data.chamadoId;
    this.chamadoCodigo = this.data.chamado_codigo;
    this.constructData();
    // this.providerId = this.data.provider_id;
    if(this.providerId === 4) {
      this.verifyMetaTemplates(this.data.integration_id);
    }
  }

  ngOnDestroy(): void {
    this.newSubs?.unsubscribe();
  }

  close(result?): void {
    if (result) {
      this.provedorSelecionado = {...this.provedorSelecionado, ...result};
      this.provedorSelecionado.chamado_id = this.chamadoId;
      this.provedorSelecionado.chamado_codigo = this.chamadoCodigo;
      this.modal.close(this.provedorSelecionado);
    } else {
      this.modal.close(false);
    }
  }

  verifyMetaTemplates(integration_id): void {
    this.newSubs = this.whatsappService.wppOfficialGetTemplates(integration_id)
      .subscribe({
        next: (results) => {
          if(!results.length) {
            Swal.fire({
              text: this.translate.instant('ALERTA_SEM_TEMPLATE_META'),
              icon: 'warning',
              confirmButtonText: 'Ok'
            });
            this.modal.close();
          }
        }, error: (error) => {
          console.error(error);
          this.toastr.show(error.error_msg ? error.error_msg : error, '', environment.toastrConfig('danger'));
          Swal.fire({
            text: this.translate.instant('ALERTA_SEM_TEMPLATE_META'),
            icon: 'warning',
            confirmButtonText: 'Ok'
          });
          this.modal.close();
        }
      });
  }

  constructData(): void {
    if(this.data.provider_id === 4) {
      this.content = {
        from: null,
        numero_destino: null,
        chamado_id: null,
        template_name: null,
        language: null,
        cliente_id: null
      };
    } else {
      this.content = {
        numero_contato: null,
        chamado_id: null,
        mensagem: null,
        nome_contato: null,
        contato_id: null,
        cliente_id: null
      };
    }
  }

  changeTemplateProperties(properties): void {
    if(properties) {
      this.content = {
        template_name: properties.text,
        language: properties.lang,
        template_content: properties.content
      };
      this.template_content = properties.content;
    } else {
      this.content = {
        template_name: null,
        language: null,
      };
      this.template_content = null;
    }
  }

  changeTemplatePropertiesZapfy(event) {
    this.content.mensagem = event.id
  }

  verifyDisabled(): boolean {
    if(this.providerId === 4) {
      return !this.content.template_name && !this.content.language;
    } else if (this.providerId === 2) {
      return !this.content.mensagem;
    }
    return true;
  }

  getMaskByCountry(number: string | number): string {
    if (!number) {
      return '';
    }
  
    return CountriesMask(number.toString().length, number.toString());
  }

  formatPhoneNumber(phone: string): string {
    const phoneLength = phone.length;
  
    if (phoneLength > 10 && phone.includes('0800')) {
      return `${phone.slice(0, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 11)}`;
    }
  
    switch (phoneLength) {
      case 4:
        return `${phone}`;
      case 5:
        return `${phone}`;
      case 6:
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)}`;
      case 7:
        return `${phone.slice(0, 3)} ${phone.slice(3, 7)}`;
      case 8:
        return `${phone.slice(0, 4)} ${phone.slice(4, 8)}`;
      case 9:
        return `${phone.slice(0, 3)} ${phone.slice(3, 6)} ${phone.slice(6, 9)}`;
      case 10:
        return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)} ${phone.slice(6, 10)}`;
      case 11:
        return `(${phone.slice(0, 2)})${phone.slice(2, 3)} ${phone.slice(3, 7)}-${phone.slice(7, 11)}`;
      case 12:
        return `${phone.slice(0, 4)} ${phone.slice(4, 8)} ${phone.slice(8, 12)}`;
      case 13:
        return `${phone.slice(0, 4)} ${phone.slice(4, 8)} ${phone.slice(8, 13)}`;
      default:
        return phone; // Retorna o número sem formato caso o comprimento não corresponda a nenhum caso
    }
  }

  escolhaSaida(dado) {
    this.provedorSelecionado = dado;
    this.providerId = dado.provider_id
    this.integrationId = dado.integracao_id
    if (this.chamadoId && this.providerId === 2) {
      this.content.mensagem = dado.mensagem_inicial_com_ticket_conciliado 
    } else if (!this.chamadoId && this.providerId === 2) {
      this.content.mensagem = dado.mensagem_inicial_sem_ticket_conciliado 
    }
  }

  retornoSelectIntegracoes(event) {
    this.integracoes = event;
    if (this.integracoes.length === 1) {
      this.provedorSelecionado = this.integracoes[0];
      this.providerId = this.provedorSelecionado.provider_id;
      this.integrationId = this.provedorSelecionado.integracao_id;
      if (this.chamadoId && this.providerId === 2) {
        this.content.mensagem = this.provedorSelecionado.mensagem_inicial_com_ticket_conciliado 
      } else if (!this.chamadoId && this.providerId === 2) {
        this.content.mensagem = this.provedorSelecionado.mensagem_inicial_sem_ticket_conciliado 
      }
    }
  }

  retornoSelectTemplatesMeta(event) {
    if (event.length === 1 && this.providerId === 4) {
      this.content = {
        template_name: event[0].name,
        language: event[0].language,
        template_content: event[0].bodyText
      };
      this.content.mensagem = event[0].bodyText;
      this.template_content = event[0].bodyText;
      this.disabledDropDown = true;
    }
  }
  
}
