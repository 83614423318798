import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { IndicacaoService } from 'src/app/services/indicacao.service';

@Component({
  selector: 'app-modal-indicacao',
  templateUrl: './modal-indicacao.component.html',
  styleUrls: ['./modal-indicacao.component.scss']
})
export class ModalIndicacaoComponent {

  newSubs: Subscription;
  indicacaoList = [];
  rows: number = 1;

  indicacao = {
    nome: '',
    empresa: '',
    email: '',
    telefone: '',
    observacao: ''
  }

  constructor(
    public modal: NgbActiveModal,
    private toastr: ToastrService,
    public translate: TranslateService,
    private indicacaoService: IndicacaoService,
  ) {
  }

  ngOnInit(): void {
    this.listIndicacao()
    
  }

  ajustRows(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;

    if(textarea.scrollHeight > textarea.offsetHeight && this.rows < 5){
      this.rows++;
    }

    if(textarea.scrollHeight <= textarea.offsetHeight && this.rows > 1){
      this.rows = Math.max(1, Math.min(5, Math.ceil(textarea.scrollHeight / 24)))
    }
  }

  enviarIndicacao() {
    this.newSubs = this.indicacaoService.adicionarIndicacao(this.indicacao)
        .subscribe(result => {
          this.toastr.show(this.translate.instant('SALVO_SUCESSO'), '', environment.toastrConfig('success'));

          this.listIndicacao()
        }, error => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
  }

  listIndicacao() {
    this.newSubs = this.indicacaoService.getIndicacao()
        .subscribe(result => {
          this.indicacaoList = result;
        }, error => {
          console.error(error);
          this.toastr.show(error, '', environment.toastrConfig('danger'));
        })
  }

  verificaRequerido(valor): boolean {
    if (valor !== undefined && valor !== null && valor !== "") {
      return false;
    } else {
      return true;
    }
  }

  verificaSeDesativa(): boolean {
    if(this.indicacao.nome && this.indicacao.nome !== "" &&
      this.indicacao.email && this.indicacao.email !== "" &&
      this.indicacao.telefone && this.indicacao.telefone !== "") {
      return false;
    } else {
      return true;
    }
  }

}
