import { CommonModule } from '@angular/common';
import { Component, Input, type OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-visualizador-arquivos',
    templateUrl: './visualizador-arquivos.component.html',
    styleUrls: ['./visualizador-arquivos.component.scss'],
})
export class VisualizadorArquivosComponent implements OnInit {

    @Input() fileUrl!: string;
    fileType!: 'image' | 'audio' | 'pdf' | 'video' | null;
    sanitizedUrl!: SafeResourceUrl;

    constructor(
        private sanitizer: DomSanitizer,
        public activeModal: NgbActiveModal
    ) {}

    ngOnInit(): void { 
        this.determineFileType();
        if (this.fileType === 'pdf') {
            this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.fileUrl);
        }
    }

    private determineFileType(): void {
        if (this.fileUrl.match(/\.(jpg|jpeg|png|gif|bmp|webp)$/i)) {
          this.fileType = 'image';
        } else if (this.fileUrl.match(/\.(mp3|wav|ogg)$/i)) {
          this.fileType = 'audio';
        } else if (this.fileUrl.match(/\.(mp4|webm|ogg|mov)$/i)) {
            this.fileType = 'video';
        } else if (this.fileUrl.match(/\.pdf$/i)) {
          this.fileType = 'pdf';
        } else {
          this.fileType = null;
        }
    }

    close() {
        this.activeModal.close();
    }

    abrirNovaGuia(): void {
      window.open(this.fileUrl, '_blank');
    }
}
