import { Pipe, PipeTransform } from "@angular/core";
import { MaskApplierService as mas } from "ngx-mask";

@Pipe({
  name: "phone",
})

export class PhonePipe implements PipeTransform {
    constructor(private maskService: mas) {}
    transform(val: any, codPais?: any) {    
        if(!val) return    
        let ddi = parseInt(codPais);
        if (countryCode.has(ddi)) {
            let masks = countryCode.get(ddi);
            let maskedVal = this.applicarMascara(val, masks);
            return `+${codPais} ${maskedVal}`;
        /* caso não haja mascaras definidas em "countryCode" */
        } else {
            if (codPais) return `+${codPais} ${val}`;
            else return `${val}`;
        }
    }

    showMascaraInput(val: any, codPais?: any) {        
        let ddi = parseInt(codPais);
        
        if (countryCodeInput.has(ddi)) {            
            let masks = countryCodeInput.get(ddi);
            return `${masks[0]}`;
        /* caso não haja mascaras definidas em "countryCode" */
        } else {
            if (codPais) return "(00) 0000-0000 || (00) 00000-0000";
            else return "(00) 0000-0000 || (00) 00000-0000";
        }
    }

    applicarMascara(value: string, masks: string[]) {
        if(masks.length > 1){
            let matchedMasks = [];
            masks.forEach((m, i) => {
                //console.log(m.replace(/[\(\)-\s]/g, ''))
                if(m.replace(/[\(\)-\s]/g, '').length === value.length){
                    matchedMasks.push(masks[i])
                }
            })
            
            if(matchedMasks.length > 0){
                let mascara = this.escolheMascara(value, matchedMasks)
                return this.maskService.applyMask(value, mascara);
            } else {
                return value
            }
            
        } else {
            return this.maskService.applyMask(value, masks[0]);
        }
    }

    escolheMascara(number: string, matchedMasks: string[]) {
        /* como diferenciar numeros com mesma length? */        
        if(matchedMasks.length > 1){
            if(number.includes('0800')){
                return matchedMasks[matchedMasks.length-1]
            } else {
                return matchedMasks[0]
            }
        } else {
            return matchedMasks[0]
        }
    }
}

/*mascara para números 0800 sempre no final do array */
const countryCode = new Map([
    [55, ["(00) 0000-0000", "(00) 00000-0000", "0000 000 0000"]],
    //[55, ["(00) 0000-0000 || (00) 00000-0000", "0000 000 0000"]],
    [1, ["(000) 000-0000"]],
]);

const countryCodeInput = new Map([
    [351, ["(00) 000 000 000", "00000000000", "00000-0000", "000 000 000", "000000000", "00 000000000", "00 000 000 000"]],
    [55, ["(00) 0000-0000 || (00) 00000-0000", "0000 000 0000"]],
    [1, ["(000) 000-0000"]],
]);